/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: $white;
    font-size: 15px;
    height: 40px;
    padding: 0;
    color: $gray;

    .contact-info {
        a {
            line-height: 0;
            color: $gray;
            transition: 0.3s;

            &:hover {
                color: $depil;
            }
        }

        i {
            color: $depil;
            line-height: 0;
            margin-right: 5px;
            margin-left: 10px;
        }

        .phone-icon {
            margin-left: 15px;
        }
    }



    .social-links {
        a {
            color: $gray;
            padding: 4px 12px;
            display: inline-block;
            line-height: 1px;
            transition: 0.3s;
            font-size: 1.5em;

            &:hover {
                color: $depil;
            }
        }
    }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    background: $white;
    transition: all 0.5s;
    z-index: 997;
    height: 70px;

    &.fixed-top {
        background: $white;
    }

    .idiomaEs {
        border-radius: 20px 0px 0px 20px;
        margin-right: 0;
        border: 0px;
        margin-left: 25px;
    }

    .idiomaEn {
        border-radius: 0px 20px 20px 0px;
        margin-left: 0px;
        border-left: solid 1px #fff !important;
        border: 0px;
    }

    .appointment-btn {
        background: $primary;
        color: #fff;
        padding: 8px 25px;
        white-space: nowrap;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        font-size: 14px;
        display: inline-block;
    }

    @media (max-width: 430px) {
        .appointment-btn {
            padding: 4px 13px;
            font-size: 12px;
        }

        .idiomaEs {
            margin-left: 8px;
        }
    }

    .appointment-btn:hover {
        background: #fff;
        color: $primary;
        border: solid 1px $primary !important;
    }

    .logo {
        font-size: 30px;
        margin: 0;
        padding: 0;
        line-height: 1;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;

        a {
            color: $depil;
        }

        img {
            max-height: 60px;
        }
    }
}

    .scrolled-offset {
        margin-top: 70px;
    }
