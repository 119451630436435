/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 40px 0;
  overflow: hidden;
}

.section-title {
    text-align: center;
    padding: 30px 0;
    position: relative;

    h2 {
        font-size: 32px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 0;
        color: $secondary;
        position: relative;
        z-index: 2;
    }

    span {
        position: absolute;
        top: 30px;
        color: lighten($secondary, 86);
        left: 0;
        right: 0;
        z-index: 1;
        font-weight: 700;
        font-size: 52px;
        text-transform: uppercase;
        line-height: 0;
    }

    p {
        margin-bottom: 0;
        position: relative;
        z-index: 2;
    }

    @media (max-width: 575px) {
        h2 {
            font-size: 28px;
            margin-bottom: 15px;
        }

        span {
            font-size: 38px;
        }
    }

    
}
.section-map {
    
    padding: 30px 0;
    position: relative;

    #map{
        height:600px;
    }
}

.img-alto {
    max-height: 100%;
}
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    .img-alto{
        max-height:100%;
    }
  .content {
    h3 {
      font-weight: 600;
      font-size: 26px;
      color:$depil;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin-bottom:0;
      li {
        padding-bottom: 10px;
      }
      i {
        font-size: 20px;
        padding-right: 8px;
        color: $depil;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

/*--------------------------------------------------------------
# centros
--------------------------------------------------------------*/

.centros {
    padding-top: 10px;

    .box {
        padding: 30px 20px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        transition: all ease-in-out 0.3s;
        height: 100%;
        text-align: center;

        span {
            display: block;
            font-size: 22px;
            font-weight: 700;
            color: $primary;
        }

        h4 {
            font-size: 18px;
            font-weight: 600;
            padding: 0;
            margin: 20px 0;
            color: $secondary;
        }

        p {
            color: $secondary;
            font-size: 15px;
            margin: 0;
            padding: 0;
        }

        &:hover {
            background: $primary;
            padding: 20px 20px 40px 20px;
            box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);

            span, h4 {
                color: $white;
            }
        }
    }
}

/*--------------------------------------------------------------
# exp
--------------------------------------------------------------*/

.exp {
    .content {
        h3 {
            font-weight: 600;
            font-size: 26px;
            color: $depil;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding-bottom: 10px;
            }

            i {
                font-size: 20px;
                padding-right: 4px;
                color: $depil;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {

  padding-top: 10px;

  .box {
    padding: 50px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
    transition: all ease-in-out 0.3s;

    span {
      display: block;
      font-size: 28px;
      font-weight: 700;
      color: lighten($primary, 20);
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
      padding: 0;
      margin: 20px 0;
      color: $secondary;
    }

    p {
      color: lighten($default, 40);
      font-size: 15px;
      margin: 0;
      padding: 0;
    }

    &:hover {
      background: $primary;
      padding: 30px 30px 70px 30px;
      box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
      span, h4, p {
        color: $white;
      }
    }
  }
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  background: lighten($secondary, 85);
  padding: 15px 0;
  text-align: center;

  img {
    width: 50%;
    filter: grayscale(100);
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    &:hover {
      filter: none;
      transform: scale(1.2);
    }

    @media (max-width: 768px) {
      width: 40%;
    }
  }
}

/*--------------------------------------------------------------
#tratamientos
--------------------------------------------------------------*/
.tratamientos {

    .icon-box {
        text-align: center;
        padding: 10px;
        transition: all ease-in-out 0.3s;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);

        .icon {
            margin: 0 auto;
            width: 64px;
            height: 64px;
            background: $primary;
            transition: all .3s ease-out 0s;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            transform-style: preserve-3d;

            i {
                color: #fff;
                font-size: 28px;
            }


            &::before {
                position: absolute;
                content: '';
                left: -8px;
                top: -8px;
                height: 100%;
                width: 100%;
                background: lighten($depil, 50);
                transition: all .3s ease-out 0s;
                transform: translateZ(-1px);
            }
        }

        h3 {
            font-weight: 700;
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 24px;
            color: $depil;

            a {
                // color: $secondary;
                color: $depil;
            }
        }

        p {
            line-height: 24px;
            font-size: 14px;
            margin-bottom: 0;
        }

        &:hover {
            background: $depil;
            border-color: $primary;
            padding: 18px 30px 30px 30px;

            .icon {
                background: #fff;

                i {
                    color: $primary;
                }

                &::before {
                    background: lighten($depil, 10);
                }
            }

            h3 a {
                color: $white;
            }

            a u {
                color: $white;
            }

            p {
                color: $secondary;
            }
        }
    }
}

/*--------------------------------------------------------------
#tratamientos
--------------------------------------------------------------*/

.secundario {
    text-align: center;
    margin-bottom: 70px;

    h3 {
        color: $primary;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        font-weight: bold;
    }

    p {
        b {
            font-size: 24px;
        }
    }
}

    @media (min-width: 991px) {
        .centrado {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

    }

.container {
    p {
        b {
            font-size: 24px;
        }
    }
}

.icon-box {
    text-align: center;
    padding: 10px;
    transition: all ease-in-out 0.3s;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);

    .icon {
        margin: 0 auto;
        width: 64px;
        height: 64px;
        background: $primary;
        transition: all .3s ease-out 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        transform-style: preserve-3d;

        i {
            color: #fff;
            font-size: 28px;
        }


        &::before {
            position: absolute;
            content: '';
            left: -8px;
            top: -8px;
            height: 100%;
            width: 100%;
            background: lighten($depil, 50);
            transition: all .3s ease-out 0s;
            transform: translateZ(-1px);
        }
    }

    h4 {
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 24px;
        color: $depil;

        a {
            // color: $secondary;
            color: $depil;
        }
    }

    p {
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 0;
    }

    &:hover {
        background: $depil;
        border-color: $primary;
        padding: 18px 18px 18px 18px;

        .icon {
            background: #fff;

            i {
                color: $primary;
            }

            &::before {
                background: lighten($depil, 10);
            }
        }

        h4 a {
            color: $white;
        }

        p {
            color: $secondary;
        }
    }
}

.testimonials {
    background: url("../public/images/fondoopinion2.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding:0;
    margin-top:30px;
    margin-bottom:30px;
}
.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    position: relative;
    right: -5px;
    top: 10px;
    -webkit-transform: scale(-1);
    transform: scale(-1);
}
.testimonials .testimonial-item .h3, .testimonials .testimonial-item h3 {
    color: #b66c2a;
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0 5px;
}
.testimonials .testimonial-item .h4, .testimonials .testimonial-item h4 {
    color: #b66c2a;
    font-size: 14px;
    margin: 0 0 10px;
}
.testimonials .testimonial-item p {
    font-size: 18px;
    font-weight: 700;
}
.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}
.testimonials .section-title h2 {
    
}
.testimonials .swiper-slide {
    background: rgba(5, 170, 51, .0);
}
/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: url("../public/images/eligetucentro.png");
    background-size: cover;
    padding: 200px 0px 40px 0;
    background-position: center;

    h3 {
        color: $primary;
        font-size: 32px;
        font-weight: 700;
        text-shadow: 1px 1px 2px black;
    }

    p {
        color: $primary;
        text-shadow: 1px 1px 2px black;
        font-size: 24px;
    }

    .cta-btn {
        background-color: rgba(255, 255, 255, 0.95);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 28px;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 10px;
        border: 2px solid $primary;
        color: $primary;

        &:hover {
            background: $primary;
            border: 2px solid $primary;
            color: #fff;
        }
    }
}

.job {
    background: url("../public/images/trabaja.png");
    background-size: cover;
    padding: 120px 0px 120px 0;
    background-position: center;

    h3 {
        color: $primary;
        font-size: 32px;
        font-weight: 700;
        text-shadow: 1px 1px 2px black;
    }

    p {
        color: $primary;
        text-shadow: 1px 1px 2px black;
        font-size: 24px;
    }

    .cta-btn {
        background-color: rgba(255, 255, 255, 0.95);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 28px;
        transition: 0.5s;
        font-weight: bold;
        margin-top: 10px;
        border: 2px solid $primary;
        color: $primary;

        &:hover {
            background: $primary;
            border: 2px solid $primary;
            color: #fff;
        }
    }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {

  #portfolio-flters {
    padding:0;
    margin:0 auto 25px auto;
    list-style:none;
    text-align:center;
    border-radius: 50px;

    li {
      cursor: pointer;
      display: inline-block;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      color: $default;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;
      font-family: $font-secondary;

      &:hover, &.filter-active {
        color: lighten($primary, 10);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .portfolio-item {
    margin-bottom: 30px;

    .portfolio-info {
      opacity: 0;
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 0;
      z-index: 3;
      transition: all ease-in-out 0.3s;
      background: rgba($white, .9);
      padding: 15px;

      h4 {
        font-size: 18px;
        color: $white;
        font-weight: 600;
        color: $secondary;
      }

      p {
        color: lighten($secondary, 20);
        font-size: 14px;
        margin-bottom: 0;
      }

      .preview-link, .details-link {
        position: absolute;
        right: 40px;
        font-size: 24px;
        top: calc(50% - 18px);
        color: lighten($secondary, 10);
        &:hover {
          color: $primary;
        }
      }

      .details-link {
        right: 10px;
      }
      
    }

    .portfolio-links {
      opacity: 0;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 3;
      position: absolute;
      transition: all ease-in-out 0.3s;

      a {
        color: $white;
        margin: 0 2px;
        font-size: 28px;
        display: inline-block;
        transition: 0.3s;

        &:hover {
          color: lighten($primary, 10);
        }
      }

    }

    &:hover {
      .portfolio-info {
        opacity: 1;
        bottom: 20px;
      }
    }
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;

    .portfolio-details-slider {
        img {
            width: 100%;
        }

        .swiper-pagination {
            margin-top: 20px;
            

            .swiper-pagination-bullet {
                width: 14px;
                height: 14px;
                background-color: #fff;
                opacity: 1;
                border: 1px solid $primary;
            }

            .swiper-pagination-bullet-active {
                background-color: $primary;
            }
        }
    }

    .portfolio-info {
        padding: 30px;
        box-shadow: 0px 0 30px rgba($secondary, 0.08);

        h3 {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
        }

        ul {
            list-style: none;
            padding: 0;
            font-size: 15px;

            li + li {
                margin-top: 10px;
            }
        }
    }

    .portfolio-description {
        padding-top: 30px;

        h2 {
            font-size: 26px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        p {
            padding: 0;
        }
    }
}

.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 65px;
    z-index: 99999;
    background: #23610B;
    width: 55px;
    height: 55px;
    border-radius: 50px;
    transition: all 0.4s;

    i {
        font-size: 24px;
        color: #fff;
        line-height: 0;
    }

    &:hover {
        background: lighten(#23610B, 10);
        color: #fff;
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &#WhatsappFlotante {
        .whatsapp {
            margin-top: 5px;
        }

        bottom: 70px;
        visibility: visible;
        opacity: 1;
    }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {

  .box {
    padding: 20px;
    background: #f9f9f9;
    text-align: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    h3 {
      font-weight: 400;
      padding: 15px;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
      color: $secondary;
    }
  
    h4 {
      font-size: 42px;
      color: $primary;
      font-weight: 500;
      font-family: $font-default;
      margin-bottom: 20px;
  
      sup {
        font-size: 20px;
        top: -15px;
        left: -3px;
      }
  
      span {
        color: #bababa;
        font-size: 16px;
        font-weight: 300;
      }
    }
  
    ul {
      padding: 0;
      list-style: none;
      color: $secondary;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
  
      li {
        padding-bottom: 16px;
      }
  
      i {
        color: $primary;
        font-size: 18px;
        padding-right: 4px;
      }
      .na {
        color: #ccc;
        text-decoration: line-through;
      }
    }
  
    .btn-wrap {
      padding: 15px;
      text-align: center;
    }
  
    .btn-buy {
      background: $primary;
      display: inline-block;
      padding: 10px 40px;
      color: #fff;
      transition: none;
      font-size: 14px;
      font-weight: 400;
      font-family: $font-primary;
      font-weight: 600;
      transition: 0.3s;
      &:hover {
        background: lighten($primary, 10);
      }
    }

  }

  .featured {
    background: $primary;
    box-shadow: none;
    color: $white;

    h3, h4, ul, ul li, ul .na {
      color: $white;
    }
  
    h4 span {
      color: rgba($white, .58);
    }
  
    .btn-wrap {
      padding: 15px;
      text-align: center;
    }
  
    .btn-buy {
      background: rgba($white, .2);
      &:hover {
        background: $white;
        color: $primary;
      }
    }
  }

}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {

  .member {
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
    padding: 30px 20px;
    background: $white;
    
    img {
      max-width: 60%;
      border-radius: 50%;
      margin: 0 0 30px 0;
    }


    h4 {
      font-weight: 700;
      margin-bottom: 2px;
      font-size: 18px;
    }

    span {
      font-style: italic;
      display: block;
      font-size: 13px;
    }

    p {
      padding-top: 10px;
      font-size: 14px;
      font-style: italic;
      color: lighten($default, 40);
    }

    .social {
      margin-top: 15px;
      a {
        color: lighten($default, 30%);
        transition: 0.3s;
        &:hover {
          color: $primary;
        }

      }

      i {
        font-size: 18px;
        margin: 0 2px;
      }
    }
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {

    .button{
        background: $primary;
        border: 0;
        padding: 10px 44px;
        color: #fff;
        transition: 0.4s;
        margin-top:20px;

        &:hover {
            background: lighten($primary, 10%);
        }
    }

    .info-box {
        color: $default;
        text-align: center;
        box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
        padding: 50px 0 50px 0;

        i {
            font-size: 50px;
            color: $depil;
            border-radius: 50%;
            padding: 8px;
            border: 2px dotted $depil;
        }

        h3 {
            font-size: 25px;
            color: lighten($default, 20);
            font-weight: 700;
            margin: 10px 0;
        }

        p {
            padding: 0;
            line-height: 24px;
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    .php-email-form {
        box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
        padding: 30px;

        .error-message {
            display: none;
            color: #fff;
            background: #ed3c0d;
            text-align: left;
            padding: 15px;
            font-weight: 600;

            & br + br {
                margin-top: 25px;
            }
        }

        .sent-message {
            display: none;
            color: #fff;
            background: #18d26e;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }

        .loading {
            display: none;
            background: #fff;
            text-align: center;
            padding: 15px;

            &:before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin: 0 10px -6px 0;
                border: 3px solid #18d26e;
                border-top-color: #eee;
                animation: animate-loading 1s linear infinite;
            }
        }

        input, textarea {
            border-radius: 0;
            box-shadow: none;
            font-size: 14px;

            &:focus {
                background-color: $depil;
            }
        }

        input {
            padding: 10px 15px;
        }

        textarea {
            padding: 12px 15px;
        }

        button [type="submit"] {
            background: $primary;
            border: 0;
            padding: 10px 24px;
            color: #fff;
            transition: 0.4s;

            &:hover {
                background: lighten($primary, 10%);
            }
        }
    }

    @keyframes animate-loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: $depil;

    h2 {
        font-size: 30px;
        font-weight: 600;
        color: $white;
    }

    .tituloApartado {
        color: #fff;
    }

    ol {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0 0 0px 0;
        margin: 0;
        font-size: 18px;

        li + li {
            padding-left: 10px;
            color:$white;
        }

        li + li::before {
            display: inline-block;
            padding-right: 10px;
            color: lighten($secondary, 10);
            content: "/";
        }

        li a {
            color: #fff;
        }
    }
}

/*--------------------------------------------------------------
# Apartados
--------------------------------------------------------------*/

.inner-page {
    .content ul i {
        font-size: 20px;
        padding-right: 4px;
        color: #a4d764;
    }
}



