/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: calc(100vh - 110px);
    background: url("../public/images/Portada.jpg") top center;
    background-size: cover;
    position: relative;
    background-position: right;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }


    h1 {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 1px 2px black;
    }

    h2 {
        color: #fff;
        text-shadow: 1px 1px 2px black;
        margin-bottom: 30px;
        font-size: 24px;
    }

    .btn-get-started {
        font-family: $font-primary;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.5px;
        display: inline-block;
        padding: 10px 28px;
        transition: 0.5s;
        border: 2px solid #fff;
        color: #fff;

        &:hover {
            background: #fff;
            border-color: $primary;
            color: $primary;
        }
    }

    @media (min-width: 1024px) {
        background-attachment: fixed;
    }

    @media (max-width: 1200px) {
        text-align: center;
    }

    @media (max-width: 768px) {
        text-align: center;

        .container {
            padding-top: 40px;
        }

        h1 {
            font-size: 28px;
            line-height: 36px;
        }

        h2 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }
}
#home {
    padding-top: 0;
    background-position: 100%;
    background-size: cover;
    height: calc(100vh - 70px);
    position: relative;
    width: 100%;

    img {
        object-fit: fill;
        width: 100%;
    }

    .swiper {
        height: 100%;
    }

    .swiper-slide {
        background-size: cover;
        background-position: right;
        text-align: left;
    }

    .swiper-slide img {
        object-fit: cover;
        width: 100%;
        //height: 100%;
    }

    .swiper-cube {
        overflow: visible;
        height: 0;
    }

    .msnprincipal {
        background: hsla(0,0%,95%,.651);
        padding: 20px;
        margin-left: 15%;
        text-align: center;
        border-radius:15px;
    }

    .btn-get-started {
        font-family: $font-primary;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.5px;
        display: inline-block;
        padding: 10px 28px;
        transition: 0.5s;
        border: 2px solid #23610B;
        color: #23610B;

        &:hover {
            background: #fff;
            border-color: #23610B;
            color: #23610B;
        }
    }

    h1 {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        text-transform: uppercase;
        color: #23610B;
        //text-shadow: 1px 1px 2px black;
    }

    h2 {
        color: #23610B;
        //text-shadow: 1px 1px 2px black;
        margin-bottom: 30px;
        font-size: 24px;
    }

    span {
        color: white;
        font-weight: bold;
    }

    .btn {
        background: rgba(0,0,0,0.6);
        border: none;

        &:hover {
            background: rgba(255,255,255,0.6);
        }
    }

    .slider-caption {
        position: relative;
        //background-color: rgba(255,255,255,0.2);
        color: white;
        padding: 4px;
        font-size: 17px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 18px;
    }

    @media (max-width: 960px) {
    }

    @media (min-width: 1024px) {
        background-attachment: fixed;
    }

    @media (max-width: 1200px) {
        text-align: center;

        .swiper-slide {
            text-align: center;
        }

        .msnprincipal {
            margin: auto;
        }
    }

    @media (max-width: 768px) {
        text-align: center;

        .swiper-slide {
            text-align: center;
        }

        .container {
            padding-top: 40px;
        }

        h1 {
            font-size: 32px;
            line-height: 36px;
        }

        h2 {
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }
}
