.swiper {
width: 100%;
height: 100%;
}
.swiper-slide {
text-align: center;
font-size: 18px;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
}
.swiper-slide img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}
.swiper {
width: 100%;
height: 250px;
margin-left: auto;
margin-right: auto;
}
.swiper-slide {
background-size: cover;
background-position: center;
}
.mySwiperIMGgrande {
height: 600px;
width: 100%;
}
.mySwiperGaleria {
height: 200px;
box-sizing: border-box;
padding: 10px 0;
}
.mySwiperGaleria .swiper-slide {
width: 25%;
height: 100%;
opacity: 0.4;
}
.mySwiperGaleria .swiper-slide-thumb-active {
opacity: 1;
}
.swiper-slide img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}

