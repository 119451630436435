/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: $font-office;
    color: $default;
}

a {
  color: $secondary;
  text-decoration: none;
}

a:hover {
  color: $primary;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-office;
}
ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}


.sliderPrincipal {
    .swiper-pagination-bullet {
        background: white;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
        background-color: white;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        opacity: 1;
    }
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: $depil;
    width: 40px;
    height: 40px;
    transition: all 0.4s;
    border: 2px solid #fff;

    i {
        font-size: 28px;
        color: $white;
        line-height: 0;
    }

    &:hover {
        background: lighten($white, 8);
        color: $depil !important;
        border: 2px solid $depil;

        i {
            font-size: 28px;
            color: $depil !important;
            line-height: 0;
        }
    }



    &.active {
        visibility: visible;
        opacity: 1;
    }
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid $primary;
  border-top-color: lighten($secondary, 84);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

