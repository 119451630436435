/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: $depil;
    padding: 0 0 30px 0;
    color: $secondary;
    font-size: 14px;

    .footer-kit {
        padding: 60px 0 30px 0;
        background: $white;
    }

    .footer-top {
        padding: 60px 0 30px 0;

        .footer-info {
            margin-bottom: 15px;
            background: $primary;
            color: $secondary;
            /*border-top: 4px solid $secondary;*/
            text-align: center;


            h3 {
                font-size: 36px;
                margin: 0 0 20px 0;
                padding: 2px 0 2px 0;
                line-height: 1;
                font-weight: 700;
            }

            .img-footer {
                max-width: 40%;
                height: auto;
            }

            p {
                font-size: 17px;
                margin-top: 10px;
                line-height: 24px;
                margin-bottom: 0;
            }
        }

        .social-links {
            a {
                font-size: 18px;
                display: inline-block;
                background: lighten($secondary, 5);
                color: $white;
                line-height: 1;
                padding: 8px 0;
                margin-right: 4px;
                border-radius: 50%;
                text-align: center;
                width: 36px;
                height: 36px;
                transition: 0.3s;

                &:hover {
                    background: $depil;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        h4 {
            font-size: 16px;
            font-weight: 600;
            color: $white;
            position: relative;
            padding-bottom: 12px;
        }

        .footer-links {
            margin-bottom: 30px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                i {
                    padding-right: 2px;
                    color: $white;
                    font-size: 18px;
                    line-height: 1;
                }

                li {
                    padding: 10px 0;

                    &:first-child {
                        padding-top: 0;
                    }

                    display: flex;
                    align-items: center;
                }

                a {
                    color: $secondary;
                    transition: 0.3s;
                    display: inline-block;
                    line-height: 1;
                    font-size: 17px;

                    &:hover {
                        color: $white;
                    }
                }
            }

            .social-links {
                a {
                    font-size: 18px;
                    display: inline-block;
                    background: $primary;
                    color: $white;
                    line-height: 1;
                    padding: 8px 0;
                    margin-right: 4px;
                    border-radius: 4px;
                    text-align: center;
                    width: 36px;
                    height: 36px;
                    transition: 0.3s;

                    &:hover {
                        background: lighten($primary, 10);
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }

        .footer-newsletter {

            form {
                margin-top: 30px;
                background: #fff;
                padding: 6px 10px;
                position: relative;
                border-radius: 4;

                input[type="email"] {
                    border: 0;
                    padding: 4px;
                    width: calc(100% - 110px);
                }

                input[type="submit"] {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border: 0;
                    background: none;
                    font-size: 16px;
                    padding: 0 20px;
                    background: $primary;
                    color: $white;
                    transition: 0.3s;
                    border-radius: 4;

                    &:hover {
                        background: lighten($primary, 10);
                    }
                }
            }
        }
    }

    .copyright {
        border-top: 1px solid lighten($secondary, 5);
        text-align: center;
        padding-top: 30px;
    }

    .credits {
        padding-top: 5px;
        text-align: center;
        font-size: 13px;
        color: $white;

        a {
            color: lighten($primary, 10);
        }
    }

    @media (max-width: 575px) {
        .footer-top .footer-info {
            margin: -20px 0 30px 0;
        }
    }
}
