/**
* Este fichero es necesario para poder modificar variables ya creadas
* No se puede hacer en el otro fichero, por que si quieres usar variables de bootstrap, no son conocidas. Aqui si se conocen
*/

$theme-colors: map-merge($theme-colors, $colors);
$theme-colors: map-merge($theme-colors, $blues);
$theme-colors: map-merge($theme-colors, $indigos);
$theme-colors: map-merge($theme-colors, $purples);
$theme-colors: map-merge($theme-colors, $pinks);
$theme-colors: map-merge($theme-colors, $reds);
$theme-colors: map-merge($theme-colors, $oranges);
$theme-colors: map-merge($theme-colors, $yellows);
$theme-colors: map-merge($theme-colors, $greens);
$theme-colors: map-merge($theme-colors, $teals);
$theme-colors: map-merge($theme-colors, $cyans);
