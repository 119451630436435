@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&family=Teko&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ysabeau+Office:wght@300&display=swap");
/*!
 * Bootstrap  v5.3.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/**
* Este fichero es necesario para poder modificar variables ya creadas
* No se puede hacer en el otro fichero, por que si quieres usar variables de bootstrap, no son conocidas. Aqui si se conocen
*/
:root,
[data-bs-theme=light] {
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #28a745;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-black: #343a40;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #a4d764;
  --bs-secondary: #191919;
  --bs-success: #28a745;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-blue: #007bff;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #28a745;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-black: #343a40;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-blue-100: #cce5ff;
  --bs-blue-200: #99caff;
  --bs-blue-300: #66b0ff;
  --bs-blue-400: #3395ff;
  --bs-blue-500: #007bff;
  --bs-blue-600: #0062cc;
  --bs-blue-700: #004a99;
  --bs-blue-800: #003166;
  --bs-blue-900: #001933;
  --bs-indigo-100: #e0cffc;
  --bs-indigo-200: #c29ffa;
  --bs-indigo-300: #a370f7;
  --bs-indigo-400: #8540f5;
  --bs-indigo-500: #6610f2;
  --bs-indigo-600: #520dc2;
  --bs-indigo-700: #3d0a91;
  --bs-indigo-800: #290661;
  --bs-indigo-900: #140330;
  --bs-purple-100: #e2d9f3;
  --bs-purple-200: #c5b3e6;
  --bs-purple-300: #a98eda;
  --bs-purple-400: #8c68cd;
  --bs-purple-500: #6f42c1;
  --bs-purple-600: #59359a;
  --bs-purple-700: #432874;
  --bs-purple-800: #2c1a4d;
  --bs-purple-900: #160d27;
  --bs-pink-100: #fad8e8;
  --bs-pink-200: #f6b2d1;
  --bs-pink-300: #f18bba;
  --bs-pink-400: #ed65a3;
  --bs-pink-500: #e83e8c;
  --bs-pink-600: #ba3270;
  --bs-pink-700: #8b2554;
  --bs-pink-800: #5d1938;
  --bs-pink-900: #2e0c1c;
  --bs-red-100: #f8d7da;
  --bs-red-200: #f1aeb5;
  --bs-red-300: #ea868f;
  --bs-red-400: #e35d6a;
  --bs-red-500: #dc3545;
  --bs-red-600: #b02a37;
  --bs-red-700: #842029;
  --bs-red-800: #58151c;
  --bs-red-900: #2c0b0e;
  --bs-orange-100: #ffe5d0;
  --bs-orange-200: #fecba1;
  --bs-orange-300: #feb272;
  --bs-orange-400: #fd9843;
  --bs-orange-500: #fd7e14;
  --bs-orange-600: #ca6510;
  --bs-orange-700: #984c0c;
  --bs-orange-800: #653208;
  --bs-orange-900: #331904;
  --bs-yellow-100: #fff3cd;
  --bs-yellow-200: #ffe69c;
  --bs-yellow-300: #ffda6a;
  --bs-yellow-400: #ffcd39;
  --bs-yellow-500: #ffc107;
  --bs-yellow-600: #cc9a06;
  --bs-yellow-700: #997404;
  --bs-yellow-800: #664d03;
  --bs-yellow-900: #332701;
  --bs-green-100: #d4edda;
  --bs-green-200: #a9dcb5;
  --bs-green-300: #7eca8f;
  --bs-green-400: #53b96a;
  --bs-green-500: #28a745;
  --bs-green-600: #208637;
  --bs-green-700: #186429;
  --bs-green-800: #10431c;
  --bs-green-900: #08210e;
  --bs-teal-100: #d2f4ea;
  --bs-teal-200: #a6e9d5;
  --bs-teal-300: #79dfc1;
  --bs-teal-400: #4dd4ac;
  --bs-teal-500: #20c997;
  --bs-teal-600: #1aa179;
  --bs-teal-700: #13795b;
  --bs-teal-800: #0d503c;
  --bs-teal-900: #06281e;
  --bs-cyan-100: #d1ecf1;
  --bs-cyan-200: #a2dae3;
  --bs-cyan-300: #74c7d4;
  --bs-cyan-400: #45b5c6;
  --bs-cyan-500: #17a2b8;
  --bs-cyan-600: #128293;
  --bs-cyan-700: #0e616e;
  --bs-cyan-800: #09414a;
  --bs-cyan-900: #052025;
  --bs-primary-rgb: 164, 215, 100;
  --bs-secondary-rgb: 25, 25, 25;
  --bs-success-rgb: 40, 167, 69;
  --bs-info-rgb: 23, 162, 184;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-blue-rgb: 0, 123, 255;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-purple-rgb: 111, 66, 193;
  --bs-pink-rgb: 232, 62, 140;
  --bs-red-rgb: 220, 53, 69;
  --bs-orange-rgb: 253, 126, 20;
  --bs-yellow-rgb: 255, 193, 7;
  --bs-green-rgb: 40, 167, 69;
  --bs-teal-rgb: 32, 201, 151;
  --bs-cyan-rgb: 23, 162, 184;
  --bs-black-rgb: 52, 58, 64;
  --bs-white-rgb: 255, 255, 255;
  --bs-gray-rgb: 108, 117, 125;
  --bs-gray-dark-rgb: 52, 58, 64;
  --bs-blue-100-rgb: 204, 229, 255;
  --bs-blue-200-rgb: 153, 202, 255;
  --bs-blue-300-rgb: 102, 176, 255;
  --bs-blue-400-rgb: 51, 149, 255;
  --bs-blue-500-rgb: 0, 123, 255;
  --bs-blue-600-rgb: 0, 98, 204;
  --bs-blue-700-rgb: 0, 74, 153;
  --bs-blue-800-rgb: 0, 49, 102;
  --bs-blue-900-rgb: 0, 25, 51;
  --bs-indigo-100-rgb: 224, 207, 252;
  --bs-indigo-200-rgb: 194, 159, 250;
  --bs-indigo-300-rgb: 163, 112, 247;
  --bs-indigo-400-rgb: 133, 64, 245;
  --bs-indigo-500-rgb: 102, 16, 242;
  --bs-indigo-600-rgb: 82, 13, 194;
  --bs-indigo-700-rgb: 61, 10, 145;
  --bs-indigo-800-rgb: 41, 6, 97;
  --bs-indigo-900-rgb: 20, 3, 48;
  --bs-purple-100-rgb: 226, 217, 243;
  --bs-purple-200-rgb: 197, 179, 230;
  --bs-purple-300-rgb: 169, 142, 218;
  --bs-purple-400-rgb: 140, 104, 205;
  --bs-purple-500-rgb: 111, 66, 193;
  --bs-purple-600-rgb: 89, 53, 154;
  --bs-purple-700-rgb: 67, 40, 116;
  --bs-purple-800-rgb: 44, 26, 77;
  --bs-purple-900-rgb: 22, 13, 39;
  --bs-pink-100-rgb: 250, 216, 232;
  --bs-pink-200-rgb: 246, 178, 209;
  --bs-pink-300-rgb: 241, 139, 186;
  --bs-pink-400-rgb: 237, 101, 163;
  --bs-pink-500-rgb: 232, 62, 140;
  --bs-pink-600-rgb: 186, 50, 112;
  --bs-pink-700-rgb: 139, 37, 84;
  --bs-pink-800-rgb: 93, 25, 56;
  --bs-pink-900-rgb: 46, 12, 28;
  --bs-red-100-rgb: 248, 215, 218;
  --bs-red-200-rgb: 241, 174, 181;
  --bs-red-300-rgb: 234, 134, 143;
  --bs-red-400-rgb: 227, 93, 106;
  --bs-red-500-rgb: 220, 53, 69;
  --bs-red-600-rgb: 176, 42, 55;
  --bs-red-700-rgb: 132, 32, 41;
  --bs-red-800-rgb: 88, 21, 28;
  --bs-red-900-rgb: 44, 11, 14;
  --bs-orange-100-rgb: 255, 229, 208;
  --bs-orange-200-rgb: 254, 203, 161;
  --bs-orange-300-rgb: 254, 178, 114;
  --bs-orange-400-rgb: 253, 152, 67;
  --bs-orange-500-rgb: 253, 126, 20;
  --bs-orange-600-rgb: 202, 101, 16;
  --bs-orange-700-rgb: 152, 76, 12;
  --bs-orange-800-rgb: 101, 50, 8;
  --bs-orange-900-rgb: 51, 25, 4;
  --bs-yellow-100-rgb: 255, 243, 205;
  --bs-yellow-200-rgb: 255, 230, 156;
  --bs-yellow-300-rgb: 255, 218, 106;
  --bs-yellow-400-rgb: 255, 205, 57;
  --bs-yellow-500-rgb: 255, 193, 7;
  --bs-yellow-600-rgb: 204, 154, 6;
  --bs-yellow-700-rgb: 153, 116, 4;
  --bs-yellow-800-rgb: 102, 77, 3;
  --bs-yellow-900-rgb: 51, 39, 1;
  --bs-green-100-rgb: 212, 237, 218;
  --bs-green-200-rgb: 169, 220, 181;
  --bs-green-300-rgb: 126, 202, 143;
  --bs-green-400-rgb: 83, 185, 106;
  --bs-green-500-rgb: 40, 167, 69;
  --bs-green-600-rgb: 32, 134, 55;
  --bs-green-700-rgb: 24, 100, 41;
  --bs-green-800-rgb: 16, 67, 28;
  --bs-green-900-rgb: 8, 33, 14;
  --bs-teal-100-rgb: 210, 244, 234;
  --bs-teal-200-rgb: 166, 233, 213;
  --bs-teal-300-rgb: 121, 223, 193;
  --bs-teal-400-rgb: 77, 212, 172;
  --bs-teal-500-rgb: 32, 201, 151;
  --bs-teal-600-rgb: 26, 161, 121;
  --bs-teal-700-rgb: 19, 121, 91;
  --bs-teal-800-rgb: 13, 80, 60;
  --bs-teal-900-rgb: 6, 40, 30;
  --bs-cyan-100-rgb: 209, 236, 241;
  --bs-cyan-200-rgb: 162, 218, 227;
  --bs-cyan-300-rgb: 116, 199, 212;
  --bs-cyan-400-rgb: 69, 181, 198;
  --bs-cyan-500-rgb: 23, 162, 184;
  --bs-cyan-600-rgb: 18, 130, 147;
  --bs-cyan-700-rgb: 14, 97, 110;
  --bs-cyan-800-rgb: 9, 65, 74;
  --bs-cyan-900-rgb: 5, 32, 37;
  --bs-primary-text: #0062cc;
  --bs-secondary-text: #6c757d;
  --bs-success-text: #208637;
  --bs-info-text: #0e616e;
  --bs-warning-text: #997404;
  --bs-danger-text: #b02a37;
  --bs-light-text: #6c757d;
  --bs-dark-text: #495057;
  --bs-primary-bg-subtle: #cce5ff;
  --bs-secondary-bg-subtle: #f8f9fa;
  --bs-success-bg-subtle: #d4edda;
  --bs-info-bg-subtle: #d1ecf1;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #99caff;
  --bs-secondary-border-subtle: #e9ecef;
  --bs-success-border-subtle: #a9dcb5;
  --bs-info-border-subtle: #a2dae3;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 52, 58, 64;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-emphasis-color: #343a40;
  --bs-emphasis-color-rgb: 52, 58, 64;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-link-color: #a4d764;
  --bs-link-color-rgb: 164, 215, 100;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #83ac50;
  --bs-link-hover-color-rgb: 131, 172, 80;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(52, 58, 64, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
  --bs-emphasis-color: #343a40;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fff3cd;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

[data-bs-theme=dark] {
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #f8f9fa;
  --bs-emphasis-color-rgb: 248, 249, 250;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-emphasis-color: #fff;
  --bs-primary-text: #66b0ff;
  --bs-secondary-text: #dee2e6;
  --bs-success-text: #7eca8f;
  --bs-info-text: #74c7d4;
  --bs-warning-text: #ffda6a;
  --bs-danger-text: #ea868f;
  --bs-light-text: #f8f9fa;
  --bs-dark-text: #dee2e6;
  --bs-primary-bg-subtle: #001933;
  --bs-secondary-bg-subtle: #212529;
  --bs-success-bg-subtle: #08210e;
  --bs-info-bg-subtle: #052025;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #343a40;
  --bs-primary-border-subtle: #004a99;
  --bs-secondary-border-subtle: #495057;
  --bs-success-border-subtle: #186429;
  --bs-info-border-subtle: #09414a;
  --bs-warning-border-subtle: #664d03;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: #fff;
  --bs-link-color: #66b0ff;
  --bs-link-hover-color: #99caff;
  --bs-link-color-rgb: 102, 176, 255;
  --bs-link-hover-color-rgb: 153, 202, 255;
  --bs-code-color: #f18bba;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(52, 58, 64, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color, inherit);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(52, 58, 64, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(52, 58, 64, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(52, 58, 64, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #343a40;
  --bs-table-bg: #edf7e0;
  --bs-table-border-color: #dbe4d0;
  --bs-table-striped-bg: #e4eed8;
  --bs-table-striped-color: #343a40;
  --bs-table-active-bg: #dbe4d0;
  --bs-table-active-color: #343a40;
  --bs-table-hover-bg: #dfe9d4;
  --bs-table-hover-color: #343a40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #343a40;
  --bs-table-bg: #d1d1d1;
  --bs-table-border-color: #c1c2c3;
  --bs-table-striped-bg: #c9c9ca;
  --bs-table-striped-color: #343a40;
  --bs-table-active-bg: #c1c2c3;
  --bs-table-active-color: #343a40;
  --bs-table-hover-bg: #c5c6c6;
  --bs-table-hover-color: #343a40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #343a40;
  --bs-table-bg: #d4edda;
  --bs-table-border-color: #c4dbcb;
  --bs-table-striped-bg: #cce4d2;
  --bs-table-striped-color: #343a40;
  --bs-table-active-bg: #c4dbcb;
  --bs-table-active-color: #343a40;
  --bs-table-hover-bg: #c8e0ce;
  --bs-table-hover-color: #343a40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #343a40;
  --bs-table-bg: #d1ecf1;
  --bs-table-border-color: #c1dadf;
  --bs-table-striped-bg: #c9e3e8;
  --bs-table-striped-color: #343a40;
  --bs-table-active-bg: #c1dadf;
  --bs-table-active-color: #343a40;
  --bs-table-hover-bg: #c5dfe4;
  --bs-table-hover-color: #343a40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #343a40;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ebe1bf;
  --bs-table-striped-bg: #f5eac6;
  --bs-table-striped-color: #343a40;
  --bs-table-active-bg: #ebe1bf;
  --bs-table-active-color: #343a40;
  --bs-table-hover-bg: #f0e5c2;
  --bs-table-hover-color: #343a40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #343a40;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #e4c7cb;
  --bs-table-striped-bg: #eecfd2;
  --bs-table-striped-color: #343a40;
  --bs-table-active-bg: #e4c7cb;
  --bs-table-active-color: #343a40;
  --bs-table-hover-bg: #e9cbce;
  --bs-table-hover-color: #343a40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #343a40;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #e4e6e7;
  --bs-table-striped-bg: #eeeff1;
  --bs-table-striped-color: #343a40;
  --bs-table-active-bg: #e4e6e7;
  --bs-table-active-color: #343a40;
  --bs-table-hover-bg: #e9ebec;
  --bs-table-hover-color: #343a40;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #d2ebb2;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(164, 215, 100, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-form-control-disabled-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #d2ebb2;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(164, 215, 100, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-form-control-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #d2ebb2;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(164, 215, 100, 0.25);
}
.form-check-input:checked {
  background-color: #a4d764;
  border-color: #a4d764;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #a4d764;
  border-color: #a4d764;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2852, 58, 64, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23d2ebb2'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(164, 215, 100, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(164, 215, 100, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #a4d764;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #e4f3d1;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #a4d764;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #e4f3d1;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating::before:not(.form-control:disabled) {
  position: absolute;
  top: var(--bs-border-width);
  left: var(--bs-border-width);
  width: calc(100% - (calc(calc(0.375em + 0.1875rem) + calc(0.75em + 0.375rem))));
  height: 1.875em;
  content: "";
  background-color: var(--bs-form-control-bg);
  border-radius: 0.375rem;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-success-text);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-success);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-success);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-success-text);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-success-text);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-danger-text);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-danger);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-danger);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-danger-text);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-danger-text);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(52, 58, 64, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #a4d764;
  --bs-btn-border-color: #a4d764;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #b2dd7b;
  --bs-btn-hover-border-color: #addb74;
  --bs-btn-focus-shadow-rgb: 147, 191, 95;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #b6df83;
  --bs-btn-active-border-color: #addb74;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #a4d764;
  --bs-btn-disabled-border-color: #a4d764;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #191919;
  --bs-btn-border-color: #191919;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #151515;
  --bs-btn-hover-border-color: #141414;
  --bs-btn-focus-shadow-rgb: 60, 60, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #141414;
  --bs-btn-active-border-color: #131313;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #191919;
  --bs-btn-disabled-border-color: #191919;
}

.btn-success {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #28a745;
  --bs-btn-border-color: #28a745;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #48b461;
  --bs-btn-hover-border-color: #3eb058;
  --bs-btn-focus-shadow-rgb: 42, 151, 68;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #53b96a;
  --bs-btn-active-border-color: #3eb058;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #28a745;
  --bs-btn-disabled-border-color: #28a745;
}

.btn-info {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #3ab0c3;
  --bs-btn-hover-border-color: #2eabbf;
  --bs-btn-focus-shadow-rgb: 27, 146, 166;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #45b5c6;
  --bs-btn-active-border-color: #2eabbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #17a2b8;
  --bs-btn-disabled-border-color: #17a2b8;
}

.btn-warning {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 225, 173, 16;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 219, 220, 222;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-blue {
  --bs-btn-color: #fff;
  --bs-btn-bg: #007bff;
  --bs-btn-border-color: #007bff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0069d9;
  --bs-btn-hover-border-color: #0062cc;
  --bs-btn-focus-shadow-rgb: 38, 143, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0062cc;
  --bs-btn-active-border-color: #005cbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #007bff;
  --bs-btn-disabled-border-color: #007bff;
}

.btn-indigo {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6610f2;
  --bs-btn-disabled-border-color: #6610f2;
}

.btn-purple {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6f42c1;
  --bs-btn-border-color: #6f42c1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5e38a4;
  --bs-btn-hover-border-color: #59359a;
  --bs-btn-focus-shadow-rgb: 133, 94, 202;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #59359a;
  --bs-btn-active-border-color: #533291;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6f42c1;
  --bs-btn-disabled-border-color: #6f42c1;
}

.btn-pink {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e83e8c;
  --bs-btn-border-color: #e83e8c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c53577;
  --bs-btn-hover-border-color: #ba3270;
  --bs-btn-focus-shadow-rgb: 235, 91, 157;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ba3270;
  --bs-btn-active-border-color: #ae2f69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e83e8c;
  --bs-btn-disabled-border-color: #e83e8c;
}

.btn-red {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-orange {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fd9137;
  --bs-btn-hover-border-color: #fd8b2c;
  --bs-btn-focus-shadow-rgb: 223, 116, 27;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fd9843;
  --bs-btn-active-border-color: #fd8b2c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}

.btn-yellow {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 225, 173, 16;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-green {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #28a745;
  --bs-btn-border-color: #28a745;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #48b461;
  --bs-btn-hover-border-color: #3eb058;
  --bs-btn-focus-shadow-rgb: 42, 151, 68;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #53b96a;
  --bs-btn-active-border-color: #3eb058;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #28a745;
  --bs-btn-disabled-border-color: #28a745;
}

.btn-teal {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #41d1a7;
  --bs-btn-hover-border-color: #36cea1;
  --bs-btn-focus-shadow-rgb: 35, 180, 138;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #4dd4ac;
  --bs-btn-active-border-color: #36cea1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #20c997;
  --bs-btn-disabled-border-color: #20c997;
}

.btn-cyan {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #3ab0c3;
  --bs-btn-hover-border-color: #2eabbf;
  --bs-btn-focus-shadow-rgb: 27, 146, 166;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #45b5c6;
  --bs-btn-active-border-color: #2eabbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #17a2b8;
  --bs-btn-disabled-border-color: #17a2b8;
}

.btn-black {
  --bs-btn-color: #fff;
  --bs-btn-bg: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2c3136;
  --bs-btn-hover-border-color: #2a2e33;
  --bs-btn-focus-shadow-rgb: 82, 88, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a2e33;
  --bs-btn-active-border-color: #272c30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #343a40;
  --bs-btn-disabled-border-color: #343a40;
}

.btn-white {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 225, 225, 226;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.btn-gray {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-gray-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2c3136;
  --bs-btn-hover-border-color: #2a2e33;
  --bs-btn-focus-shadow-rgb: 82, 88, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a2e33;
  --bs-btn-active-border-color: #272c30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #343a40;
  --bs-btn-disabled-border-color: #343a40;
}

.btn-blue-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #cce5ff;
  --bs-btn-border-color: #cce5ff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d4e9ff;
  --bs-btn-hover-border-color: #d1e8ff;
  --bs-btn-focus-shadow-rgb: 181, 203, 226;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #d6eaff;
  --bs-btn-active-border-color: #d1e8ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #cce5ff;
  --bs-btn-disabled-border-color: #cce5ff;
}

.btn-blue-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #99caff;
  --bs-btn-border-color: #99caff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #a8d2ff;
  --bs-btn-hover-border-color: #a3cfff;
  --bs-btn-focus-shadow-rgb: 138, 180, 226;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #add5ff;
  --bs-btn-active-border-color: #a3cfff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #99caff;
  --bs-btn-disabled-border-color: #99caff;
}

.btn-blue-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #66b0ff;
  --bs-btn-border-color: #66b0ff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #7dbcff;
  --bs-btn-hover-border-color: #75b8ff;
  --bs-btn-focus-shadow-rgb: 95, 158, 226;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #85c0ff;
  --bs-btn-active-border-color: #75b8ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #66b0ff;
  --bs-btn-disabled-border-color: #66b0ff;
}

.btn-blue-400 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #3395ff;
  --bs-btn-border-color: #3395ff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #52a5ff;
  --bs-btn-hover-border-color: #47a0ff;
  --bs-btn-focus-shadow-rgb: 51, 135, 226;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #5caaff;
  --bs-btn-active-border-color: #47a0ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #3395ff;
  --bs-btn-disabled-border-color: #3395ff;
}

.btn-blue-500 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #007bff;
  --bs-btn-border-color: #007bff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0069d9;
  --bs-btn-hover-border-color: #0062cc;
  --bs-btn-focus-shadow-rgb: 38, 143, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0062cc;
  --bs-btn-active-border-color: #005cbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #007bff;
  --bs-btn-disabled-border-color: #007bff;
}

.btn-blue-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0062cc;
  --bs-btn-border-color: #0062cc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0053ad;
  --bs-btn-hover-border-color: #004ea3;
  --bs-btn-focus-shadow-rgb: 38, 122, 212;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004ea3;
  --bs-btn-active-border-color: #004a99;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0062cc;
  --bs-btn-disabled-border-color: #0062cc;
}

.btn-blue-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #004a99;
  --bs-btn-border-color: #004a99;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #003f82;
  --bs-btn-hover-border-color: #003b7a;
  --bs-btn-focus-shadow-rgb: 38, 101, 168;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #003b7a;
  --bs-btn-active-border-color: #003873;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #004a99;
  --bs-btn-disabled-border-color: #004a99;
}

.btn-blue-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #003166;
  --bs-btn-border-color: #003166;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #002a57;
  --bs-btn-hover-border-color: #002752;
  --bs-btn-focus-shadow-rgb: 38, 80, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #002752;
  --bs-btn-active-border-color: #00254d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #003166;
  --bs-btn-disabled-border-color: #003166;
}

.btn-blue-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #001933;
  --bs-btn-border-color: #001933;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00152b;
  --bs-btn-hover-border-color: #001429;
  --bs-btn-focus-shadow-rgb: 38, 60, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001429;
  --bs-btn-active-border-color: #001326;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #001933;
  --bs-btn-disabled-border-color: #001933;
}

.btn-indigo-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #e0cffc;
  --bs-btn-border-color: #e0cffc;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #e5d6fc;
  --bs-btn-hover-border-color: #e3d4fc;
  --bs-btn-focus-shadow-rgb: 198, 185, 224;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #e6d9fd;
  --bs-btn-active-border-color: #e3d4fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #e0cffc;
  --bs-btn-disabled-border-color: #e0cffc;
}

.btn-indigo-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #c29ffa;
  --bs-btn-border-color: #c29ffa;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #cbadfb;
  --bs-btn-hover-border-color: #c8a9fb;
  --bs-btn-focus-shadow-rgb: 173, 144, 222;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ceb2fb;
  --bs-btn-active-border-color: #c8a9fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #c29ffa;
  --bs-btn-disabled-border-color: #c29ffa;
}

.btn-indigo-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #a370f7;
  --bs-btn-border-color: #a370f7;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #b185f8;
  --bs-btn-hover-border-color: #ac7ef8;
  --bs-btn-focus-shadow-rgb: 146, 104, 220;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #b58df9;
  --bs-btn-active-border-color: #ac7ef8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #a370f7;
  --bs-btn-disabled-border-color: #a370f7;
}

.btn-indigo-400 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8540f5;
  --bs-btn-border-color: #8540f5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7136d0;
  --bs-btn-hover-border-color: #6a33c4;
  --bs-btn-focus-shadow-rgb: 151, 93, 247;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6a33c4;
  --bs-btn-active-border-color: #6430b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8540f5;
  --bs-btn-disabled-border-color: #8540f5;
}

.btn-indigo-500 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6610f2;
  --bs-btn-disabled-border-color: #6610f2;
}

.btn-indigo-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #520dc2;
  --bs-btn-border-color: #520dc2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #460ba5;
  --bs-btn-hover-border-color: #420a9b;
  --bs-btn-focus-shadow-rgb: 108, 49, 203;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #420a9b;
  --bs-btn-active-border-color: #3e0a92;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #520dc2;
  --bs-btn-disabled-border-color: #520dc2;
}

.btn-indigo-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3d0a91;
  --bs-btn-border-color: #3d0a91;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #34097b;
  --bs-btn-hover-border-color: #310874;
  --bs-btn-focus-shadow-rgb: 90, 47, 162;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #310874;
  --bs-btn-active-border-color: #2e086d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3d0a91;
  --bs-btn-disabled-border-color: #3d0a91;
}

.btn-indigo-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #290661;
  --bs-btn-border-color: #290661;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #230552;
  --bs-btn-hover-border-color: #21054e;
  --bs-btn-focus-shadow-rgb: 73, 43, 121;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #21054e;
  --bs-btn-active-border-color: #1f0549;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #290661;
  --bs-btn-disabled-border-color: #290661;
}

.btn-indigo-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #140330;
  --bs-btn-border-color: #140330;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #110329;
  --bs-btn-hover-border-color: #100226;
  --bs-btn-focus-shadow-rgb: 55, 41, 79;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #100226;
  --bs-btn-active-border-color: #0f0224;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #140330;
  --bs-btn-disabled-border-color: #140330;
}

.btn-purple-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #e2d9f3;
  --bs-btn-border-color: #e2d9f3;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #e6dff5;
  --bs-btn-hover-border-color: #e5ddf4;
  --bs-btn-focus-shadow-rgb: 200, 193, 216;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #e8e1f5;
  --bs-btn-active-border-color: #e5ddf4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #e2d9f3;
  --bs-btn-disabled-border-color: #e2d9f3;
}

.btn-purple-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #c5b3e6;
  --bs-btn-border-color: #c5b3e6;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #cebeea;
  --bs-btn-hover-border-color: #cbbbe9;
  --bs-btn-focus-shadow-rgb: 175, 161, 205;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #d1c2eb;
  --bs-btn-active-border-color: #cbbbe9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #c5b3e6;
  --bs-btn-disabled-border-color: #c5b3e6;
}

.btn-purple-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #a98eda;
  --bs-btn-border-color: #a98eda;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #b69fe0;
  --bs-btn-hover-border-color: #b299de;
  --bs-btn-focus-shadow-rgb: 151, 129, 195;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #baa5e1;
  --bs-btn-active-border-color: #b299de;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #a98eda;
  --bs-btn-disabled-border-color: #a98eda;
}

.btn-purple-400 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8c68cd;
  --bs-btn-border-color: #8c68cd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7758ae;
  --bs-btn-hover-border-color: #7053a4;
  --bs-btn-focus-shadow-rgb: 157, 127, 213;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #7053a4;
  --bs-btn-active-border-color: #694e9a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8c68cd;
  --bs-btn-disabled-border-color: #8c68cd;
}

.btn-purple-500 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6f42c1;
  --bs-btn-border-color: #6f42c1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5e38a4;
  --bs-btn-hover-border-color: #59359a;
  --bs-btn-focus-shadow-rgb: 133, 94, 202;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #59359a;
  --bs-btn-active-border-color: #533291;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6f42c1;
  --bs-btn-disabled-border-color: #6f42c1;
}

.btn-purple-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #59359a;
  --bs-btn-border-color: #59359a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c2d83;
  --bs-btn-hover-border-color: #472a7b;
  --bs-btn-focus-shadow-rgb: 114, 83, 169;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #472a7b;
  --bs-btn-active-border-color: #432874;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #59359a;
  --bs-btn-disabled-border-color: #59359a;
}

.btn-purple-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #432874;
  --bs-btn-border-color: #432874;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #392263;
  --bs-btn-hover-border-color: #36205d;
  --bs-btn-focus-shadow-rgb: 95, 72, 137;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #36205d;
  --bs-btn-active-border-color: #321e57;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #432874;
  --bs-btn-disabled-border-color: #432874;
}

.btn-purple-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2c1a4d;
  --bs-btn-border-color: #2c1a4d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #251641;
  --bs-btn-hover-border-color: #23153e;
  --bs-btn-focus-shadow-rgb: 76, 60, 104;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #23153e;
  --bs-btn-active-border-color: #21143a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2c1a4d;
  --bs-btn-disabled-border-color: #2c1a4d;
}

.btn-purple-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #160d27;
  --bs-btn-border-color: #160d27;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #130b21;
  --bs-btn-hover-border-color: #120a1f;
  --bs-btn-focus-shadow-rgb: 57, 49, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #120a1f;
  --bs-btn-active-border-color: #110a1d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #160d27;
  --bs-btn-disabled-border-color: #160d27;
}

.btn-pink-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #fad8e8;
  --bs-btn-border-color: #fad8e8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fbdeeb;
  --bs-btn-hover-border-color: #fbdcea;
  --bs-btn-focus-shadow-rgb: 220, 192, 207;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fbe0ed;
  --bs-btn-active-border-color: #fbdcea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #fad8e8;
  --bs-btn-disabled-border-color: #fad8e8;
}

.btn-pink-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #f6b2d1;
  --bs-btn-border-color: #f6b2d1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f7bed8;
  --bs-btn-hover-border-color: #f7bad6;
  --bs-btn-focus-shadow-rgb: 217, 160, 187;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f8c1da;
  --bs-btn-active-border-color: #f7bad6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #f6b2d1;
  --bs-btn-disabled-border-color: #f6b2d1;
}

.btn-pink-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #f18bba;
  --bs-btn-border-color: #f18bba;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f39cc4;
  --bs-btn-hover-border-color: #f297c1;
  --bs-btn-focus-shadow-rgb: 213, 127, 168;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f4a2c8;
  --bs-btn-active-border-color: #f297c1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #f18bba;
  --bs-btn-disabled-border-color: #f18bba;
}

.btn-pink-400 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ed65a3;
  --bs-btn-border-color: #ed65a3;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f07cb1;
  --bs-btn-hover-border-color: #ef74ac;
  --bs-btn-focus-shadow-rgb: 209, 95, 148;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f184b5;
  --bs-btn-active-border-color: #ef74ac;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ed65a3;
  --bs-btn-disabled-border-color: #ed65a3;
}

.btn-pink-500 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e83e8c;
  --bs-btn-border-color: #e83e8c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c53577;
  --bs-btn-hover-border-color: #ba3270;
  --bs-btn-focus-shadow-rgb: 235, 91, 157;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ba3270;
  --bs-btn-active-border-color: #ae2f69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e83e8c;
  --bs-btn-disabled-border-color: #e83e8c;
}

.btn-pink-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ba3270;
  --bs-btn-border-color: #ba3270;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9e2b5f;
  --bs-btn-hover-border-color: #95285a;
  --bs-btn-focus-shadow-rgb: 196, 81, 133;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #95285a;
  --bs-btn-active-border-color: #8c2654;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ba3270;
  --bs-btn-disabled-border-color: #ba3270;
}

.btn-pink-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8b2554;
  --bs-btn-border-color: #8b2554;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #761f47;
  --bs-btn-hover-border-color: #6f1e43;
  --bs-btn-focus-shadow-rgb: 156, 70, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6f1e43;
  --bs-btn-active-border-color: #681c3f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8b2554;
  --bs-btn-disabled-border-color: #8b2554;
}

.btn-pink-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5d1938;
  --bs-btn-border-color: #5d1938;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4f1530;
  --bs-btn-hover-border-color: #4a142d;
  --bs-btn-focus-shadow-rgb: 117, 60, 86;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4a142d;
  --bs-btn-active-border-color: #46132a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5d1938;
  --bs-btn-disabled-border-color: #5d1938;
}

.btn-pink-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2e0c1c;
  --bs-btn-border-color: #2e0c1c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #270a18;
  --bs-btn-hover-border-color: #250a16;
  --bs-btn-focus-shadow-rgb: 77, 48, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #250a16;
  --bs-btn-active-border-color: #230915;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2e0c1c;
  --bs-btn-disabled-border-color: #2e0c1c;
}

.btn-red-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #f8d7da;
  --bs-btn-border-color: #f8d7da;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f9dde0;
  --bs-btn-hover-border-color: #f9dbde;
  --bs-btn-focus-shadow-rgb: 219, 191, 195;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f9dfe1;
  --bs-btn-active-border-color: #f9dbde;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #f8d7da;
  --bs-btn-disabled-border-color: #f8d7da;
}

.btn-red-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #f1aeb5;
  --bs-btn-border-color: #f1aeb5;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f3bac0;
  --bs-btn-hover-border-color: #f2b6bc;
  --bs-btn-focus-shadow-rgb: 213, 157, 163;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f4bec4;
  --bs-btn-active-border-color: #f2b6bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #f1aeb5;
  --bs-btn-disabled-border-color: #f1aeb5;
}

.btn-red-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ea868f;
  --bs-btn-border-color: #ea868f;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ed98a0;
  --bs-btn-hover-border-color: #ec929a;
  --bs-btn-focus-shadow-rgb: 207, 123, 131;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ee9ea5;
  --bs-btn-active-border-color: #ec929a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ea868f;
  --bs-btn-disabled-border-color: #ea868f;
}

.btn-red-400 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e35d6a;
  --bs-btn-border-color: #e35d6a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c14f5a;
  --bs-btn-hover-border-color: #b64a55;
  --bs-btn-focus-shadow-rgb: 231, 117, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b64a55;
  --bs-btn-active-border-color: #aa4650;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e35d6a;
  --bs-btn-disabled-border-color: #e35d6a;
}

.btn-red-500 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-red-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #b02a37;
  --bs-btn-border-color: #b02a37;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #96242f;
  --bs-btn-hover-border-color: #8d222c;
  --bs-btn-focus-shadow-rgb: 188, 74, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8d222c;
  --bs-btn-active-border-color: #842029;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #b02a37;
  --bs-btn-disabled-border-color: #b02a37;
}

.btn-red-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #842029;
  --bs-btn-border-color: #842029;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #701b23;
  --bs-btn-hover-border-color: #6a1a21;
  --bs-btn-focus-shadow-rgb: 150, 65, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6a1a21;
  --bs-btn-active-border-color: #63181f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #842029;
  --bs-btn-disabled-border-color: #842029;
}

.btn-red-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #58151c;
  --bs-btn-border-color: #58151c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4b1218;
  --bs-btn-hover-border-color: #461116;
  --bs-btn-focus-shadow-rgb: 113, 56, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #461116;
  --bs-btn-active-border-color: #421015;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #58151c;
  --bs-btn-disabled-border-color: #58151c;
}

.btn-red-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2c0b0e;
  --bs-btn-border-color: #2c0b0e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #25090c;
  --bs-btn-hover-border-color: #23090b;
  --bs-btn-focus-shadow-rgb: 76, 48, 50;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #23090b;
  --bs-btn-active-border-color: #21080b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2c0b0e;
  --bs-btn-disabled-border-color: #2c0b0e;
}

.btn-orange-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ffe5d0;
  --bs-btn-border-color: #ffe5d0;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffe9d7;
  --bs-btn-hover-border-color: #ffe8d5;
  --bs-btn-focus-shadow-rgb: 225, 203, 186;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffead9;
  --bs-btn-active-border-color: #ffe8d5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ffe5d0;
  --bs-btn-disabled-border-color: #ffe5d0;
}

.btn-orange-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #fecba1;
  --bs-btn-border-color: #fecba1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fed3af;
  --bs-btn-hover-border-color: #fed0aa;
  --bs-btn-focus-shadow-rgb: 224, 181, 146;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fed5b4;
  --bs-btn-active-border-color: #fed0aa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #fecba1;
  --bs-btn-disabled-border-color: #fecba1;
}

.btn-orange-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #feb272;
  --bs-btn-border-color: #feb272;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #febe87;
  --bs-btn-hover-border-color: #feba80;
  --bs-btn-focus-shadow-rgb: 224, 160, 107;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fec18e;
  --bs-btn-active-border-color: #feba80;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #feb272;
  --bs-btn-disabled-border-color: #feb272;
}

.btn-orange-400 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #fd9843;
  --bs-btn-border-color: #fd9843;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fda75f;
  --bs-btn-hover-border-color: #fda256;
  --bs-btn-focus-shadow-rgb: 223, 138, 67;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fdad69;
  --bs-btn-active-border-color: #fda256;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #fd9843;
  --bs-btn-disabled-border-color: #fd9843;
}

.btn-orange-500 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fd9137;
  --bs-btn-hover-border-color: #fd8b2c;
  --bs-btn-focus-shadow-rgb: 223, 116, 27;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fd9843;
  --bs-btn-active-border-color: #fd8b2c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}

.btn-orange-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ca6510;
  --bs-btn-border-color: #ca6510;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ac560e;
  --bs-btn-hover-border-color: #a2510d;
  --bs-btn-focus-shadow-rgb: 210, 124, 52;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a2510d;
  --bs-btn-active-border-color: #984c0c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ca6510;
  --bs-btn-disabled-border-color: #ca6510;
}

.btn-orange-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #984c0c;
  --bs-btn-border-color: #984c0c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #81410a;
  --bs-btn-hover-border-color: #7a3d0a;
  --bs-btn-focus-shadow-rgb: 167, 103, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #7a3d0a;
  --bs-btn-active-border-color: #723909;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #984c0c;
  --bs-btn-disabled-border-color: #984c0c;
}

.btn-orange-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #653208;
  --bs-btn-border-color: #653208;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #562b07;
  --bs-btn-hover-border-color: #512806;
  --bs-btn-focus-shadow-rgb: 124, 81, 45;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #512806;
  --bs-btn-active-border-color: #4c2606;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #653208;
  --bs-btn-disabled-border-color: #653208;
}

.btn-orange-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #331904;
  --bs-btn-border-color: #331904;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b1503;
  --bs-btn-hover-border-color: #291403;
  --bs-btn-focus-shadow-rgb: 82, 60, 42;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #291403;
  --bs-btn-active-border-color: #261303;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #331904;
  --bs-btn-disabled-border-color: #331904;
}

.btn-yellow-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #fff3cd;
  --bs-btn-border-color: #fff3cd;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fff5d5;
  --bs-btn-hover-border-color: #fff4d2;
  --bs-btn-focus-shadow-rgb: 225, 215, 184;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fff5d7;
  --bs-btn-active-border-color: #fff4d2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #fff3cd;
  --bs-btn-disabled-border-color: #fff3cd;
}

.btn-yellow-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ffe69c;
  --bs-btn-border-color: #ffe69c;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffeaab;
  --bs-btn-hover-border-color: #ffe9a6;
  --bs-btn-focus-shadow-rgb: 225, 204, 142;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffebb0;
  --bs-btn-active-border-color: #ffe9a6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ffe69c;
  --bs-btn-disabled-border-color: #ffe69c;
}

.btn-yellow-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ffda6a;
  --bs-btn-border-color: #ffda6a;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffe080;
  --bs-btn-hover-border-color: #ffde79;
  --bs-btn-focus-shadow-rgb: 225, 194, 100;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffe188;
  --bs-btn-active-border-color: #ffde79;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ffda6a;
  --bs-btn-disabled-border-color: #ffda6a;
}

.btn-yellow-400 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ffcd39;
  --bs-btn-border-color: #ffcd39;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffd557;
  --bs-btn-hover-border-color: #ffd24d;
  --bs-btn-focus-shadow-rgb: 225, 183, 58;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffd761;
  --bs-btn-active-border-color: #ffd24d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ffcd39;
  --bs-btn-disabled-border-color: #ffcd39;
}

.btn-yellow-500 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 225, 173, 16;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-yellow-600 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #cc9a06;
  --bs-btn-border-color: #cc9a06;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d4a92b;
  --bs-btn-hover-border-color: #d1a41f;
  --bs-btn-focus-shadow-rgb: 181, 140, 15;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #d6ae38;
  --bs-btn-active-border-color: #d1a41f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #cc9a06;
  --bs-btn-disabled-border-color: #cc9a06;
}

.btn-yellow-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #997404;
  --bs-btn-border-color: #997404;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #826303;
  --bs-btn-hover-border-color: #7a5d03;
  --bs-btn-focus-shadow-rgb: 168, 137, 42;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #7a5d03;
  --bs-btn-active-border-color: #735703;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #997404;
  --bs-btn-disabled-border-color: #997404;
}

.btn-yellow-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #664d03;
  --bs-btn-border-color: #664d03;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #574103;
  --bs-btn-hover-border-color: #523e02;
  --bs-btn-focus-shadow-rgb: 125, 104, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #523e02;
  --bs-btn-active-border-color: #4d3a02;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #664d03;
  --bs-btn-disabled-border-color: #664d03;
}

.btn-yellow-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #332701;
  --bs-btn-border-color: #332701;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2101;
  --bs-btn-hover-border-color: #291f01;
  --bs-btn-focus-shadow-rgb: 82, 71, 39;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #291f01;
  --bs-btn-active-border-color: #261d01;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #332701;
  --bs-btn-disabled-border-color: #332701;
}

.btn-green-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #d4edda;
  --bs-btn-border-color: #d4edda;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #daf0e0;
  --bs-btn-hover-border-color: #d8efde;
  --bs-btn-focus-shadow-rgb: 188, 210, 195;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ddf1e1;
  --bs-btn-active-border-color: #d8efde;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #d4edda;
  --bs-btn-disabled-border-color: #d4edda;
}

.btn-green-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #a9dcb5;
  --bs-btn-border-color: #a9dcb5;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #b6e1c0;
  --bs-btn-hover-border-color: #b2e0bc;
  --bs-btn-focus-shadow-rgb: 151, 196, 163;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #bae3c4;
  --bs-btn-active-border-color: #b2e0bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #a9dcb5;
  --bs-btn-disabled-border-color: #a9dcb5;
}

.btn-green-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #7eca8f;
  --bs-btn-border-color: #7eca8f;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #91d2a0;
  --bs-btn-hover-border-color: #8bcf9a;
  --bs-btn-focus-shadow-rgb: 115, 180, 131;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #98d5a5;
  --bs-btn-active-border-color: #8bcf9a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #7eca8f;
  --bs-btn-disabled-border-color: #7eca8f;
}

.btn-green-400 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #53b96a;
  --bs-btn-border-color: #53b96a;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #6dc480;
  --bs-btn-hover-border-color: #64c079;
  --bs-btn-focus-shadow-rgb: 78, 166, 100;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #75c788;
  --bs-btn-active-border-color: #64c079;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #53b96a;
  --bs-btn-disabled-border-color: #53b96a;
}

.btn-green-500 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #28a745;
  --bs-btn-border-color: #28a745;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #48b461;
  --bs-btn-hover-border-color: #3eb058;
  --bs-btn-focus-shadow-rgb: 42, 151, 68;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #53b96a;
  --bs-btn-active-border-color: #3eb058;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #28a745;
  --bs-btn-disabled-border-color: #28a745;
}

.btn-green-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #208637;
  --bs-btn-border-color: #208637;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1b722f;
  --bs-btn-hover-border-color: #1a6b2c;
  --bs-btn-focus-shadow-rgb: 65, 152, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a6b2c;
  --bs-btn-active-border-color: #186529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #208637;
  --bs-btn-disabled-border-color: #208637;
}

.btn-green-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #186429;
  --bs-btn-border-color: #186429;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #145523;
  --bs-btn-hover-border-color: #135021;
  --bs-btn-focus-shadow-rgb: 59, 123, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #135021;
  --bs-btn-active-border-color: #124b1f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #186429;
  --bs-btn-disabled-border-color: #186429;
}

.btn-green-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #10431c;
  --bs-btn-border-color: #10431c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0e3918;
  --bs-btn-hover-border-color: #0d3616;
  --bs-btn-focus-shadow-rgb: 52, 95, 62;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d3616;
  --bs-btn-active-border-color: #0c3215;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #10431c;
  --bs-btn-disabled-border-color: #10431c;
}

.btn-green-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #08210e;
  --bs-btn-border-color: #08210e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #071c0c;
  --bs-btn-hover-border-color: #061a0b;
  --bs-btn-focus-shadow-rgb: 45, 66, 50;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #061a0b;
  --bs-btn-active-border-color: #06190b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #08210e;
  --bs-btn-disabled-border-color: #08210e;
}

.btn-teal-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #d2f4ea;
  --bs-btn-border-color: #d2f4ea;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d9f6ed;
  --bs-btn-hover-border-color: #d7f5ec;
  --bs-btn-focus-shadow-rgb: 186, 216, 209;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #dbf6ee;
  --bs-btn-active-border-color: #d7f5ec;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #d2f4ea;
  --bs-btn-disabled-border-color: #d2f4ea;
}

.btn-teal-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #a6e9d5;
  --bs-btn-border-color: #a6e9d5;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #b3ecdb;
  --bs-btn-hover-border-color: #afebd9;
  --bs-btn-focus-shadow-rgb: 149, 207, 191;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #b8eddd;
  --bs-btn-active-border-color: #afebd9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #a6e9d5;
  --bs-btn-disabled-border-color: #a6e9d5;
}

.btn-teal-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #79dfc1;
  --bs-btn-border-color: #79dfc1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #8de4ca;
  --bs-btn-hover-border-color: #86e2c7;
  --bs-btn-focus-shadow-rgb: 111, 198, 174;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #94e5cd;
  --bs-btn-active-border-color: #86e2c7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #79dfc1;
  --bs-btn-disabled-border-color: #79dfc1;
}

.btn-teal-400 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #4dd4ac;
  --bs-btn-border-color: #4dd4ac;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #68dab8;
  --bs-btn-hover-border-color: #5fd8b4;
  --bs-btn-focus-shadow-rgb: 73, 189, 156;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #71ddbd;
  --bs-btn-active-border-color: #5fd8b4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #4dd4ac;
  --bs-btn-disabled-border-color: #4dd4ac;
}

.btn-teal-500 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #41d1a7;
  --bs-btn-hover-border-color: #36cea1;
  --bs-btn-focus-shadow-rgb: 35, 180, 138;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #4dd4ac;
  --bs-btn-active-border-color: #36cea1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #20c997;
  --bs-btn-disabled-border-color: #20c997;
}

.btn-teal-600 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #1aa179;
  --bs-btn-border-color: #1aa179;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #3caf8d;
  --bs-btn-hover-border-color: #31aa86;
  --bs-btn-focus-shadow-rgb: 30, 146, 112;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #48b494;
  --bs-btn-active-border-color: #31aa86;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #1aa179;
  --bs-btn-disabled-border-color: #1aa179;
}

.btn-teal-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #13795b;
  --bs-btn-border-color: #13795b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #10674d;
  --bs-btn-hover-border-color: #0f6149;
  --bs-btn-focus-shadow-rgb: 54, 141, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0f6149;
  --bs-btn-active-border-color: #0e5b44;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #13795b;
  --bs-btn-disabled-border-color: #13795b;
}

.btn-teal-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d503c;
  --bs-btn-border-color: #0d503c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b4433;
  --bs-btn-hover-border-color: #0a4030;
  --bs-btn-focus-shadow-rgb: 49, 106, 89;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a4030;
  --bs-btn-active-border-color: #0a3c2d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d503c;
  --bs-btn-disabled-border-color: #0d503c;
}

.btn-teal-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #06281e;
  --bs-btn-border-color: #06281e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #05221a;
  --bs-btn-hover-border-color: #052018;
  --bs-btn-focus-shadow-rgb: 43, 72, 64;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #052018;
  --bs-btn-active-border-color: #051e17;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #06281e;
  --bs-btn-disabled-border-color: #06281e;
}

.btn-cyan-100 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #d1ecf1;
  --bs-btn-border-color: #d1ecf1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d8eff3;
  --bs-btn-hover-border-color: #d6eef2;
  --bs-btn-focus-shadow-rgb: 185, 209, 214;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #daf0f4;
  --bs-btn-active-border-color: #d6eef2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #d1ecf1;
  --bs-btn-disabled-border-color: #d1ecf1;
}

.btn-cyan-200 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #a2dae3;
  --bs-btn-border-color: #a2dae3;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #b0e0e7;
  --bs-btn-hover-border-color: #abdee6;
  --bs-btn-focus-shadow-rgb: 146, 194, 203;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #b5e1e9;
  --bs-btn-active-border-color: #abdee6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #a2dae3;
  --bs-btn-disabled-border-color: #a2dae3;
}

.btn-cyan-300 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #74c7d4;
  --bs-btn-border-color: #74c7d4;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #89cfda;
  --bs-btn-hover-border-color: #82cdd8;
  --bs-btn-focus-shadow-rgb: 106, 178, 190;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #90d2dd;
  --bs-btn-active-border-color: #82cdd8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #74c7d4;
  --bs-btn-disabled-border-color: #74c7d4;
}

.btn-cyan-400 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #45b5c6;
  --bs-btn-border-color: #45b5c6;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #61c0cf;
  --bs-btn-hover-border-color: #58bccc;
  --bs-btn-focus-shadow-rgb: 66, 163, 178;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #6ac4d1;
  --bs-btn-active-border-color: #58bccc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #45b5c6;
  --bs-btn-disabled-border-color: #45b5c6;
}

.btn-cyan-500 {
  --bs-btn-color: #343a40;
  --bs-btn-bg: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #3ab0c3;
  --bs-btn-hover-border-color: #2eabbf;
  --bs-btn-focus-shadow-rgb: 27, 146, 166;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #45b5c6;
  --bs-btn-active-border-color: #2eabbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: #17a2b8;
  --bs-btn-disabled-border-color: #17a2b8;
}

.btn-cyan-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #128293;
  --bs-btn-border-color: #128293;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0f6f7d;
  --bs-btn-hover-border-color: #0e6876;
  --bs-btn-focus-shadow-rgb: 54, 149, 163;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e6876;
  --bs-btn-active-border-color: #0e626e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #128293;
  --bs-btn-disabled-border-color: #128293;
}

.btn-cyan-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0e616e;
  --bs-btn-border-color: #0e616e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0c525e;
  --bs-btn-hover-border-color: #0b4e58;
  --bs-btn-focus-shadow-rgb: 50, 121, 132;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0b4e58;
  --bs-btn-active-border-color: #0b4953;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0e616e;
  --bs-btn-disabled-border-color: #0e616e;
}

.btn-cyan-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #09414a;
  --bs-btn-border-color: #09414a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #08373f;
  --bs-btn-hover-border-color: #07343b;
  --bs-btn-focus-shadow-rgb: 46, 94, 101;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #07343b;
  --bs-btn-active-border-color: #073138;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #09414a;
  --bs-btn-disabled-border-color: #09414a;
}

.btn-cyan-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #052025;
  --bs-btn-border-color: #052025;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #041b1f;
  --bs-btn-hover-border-color: #041a1e;
  --bs-btn-focus-shadow-rgb: 43, 65, 70;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #041a1e;
  --bs-btn-active-border-color: #04181c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #052025;
  --bs-btn-disabled-border-color: #052025;
}

.btn-outline-primary {
  --bs-btn-color: #a4d764;
  --bs-btn-border-color: #a4d764;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #a4d764;
  --bs-btn-hover-border-color: #a4d764;
  --bs-btn-focus-shadow-rgb: 164, 215, 100;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #a4d764;
  --bs-btn-active-border-color: #a4d764;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #a4d764;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a4d764;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #191919;
  --bs-btn-border-color: #191919;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #191919;
  --bs-btn-hover-border-color: #191919;
  --bs-btn-focus-shadow-rgb: 25, 25, 25;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #191919;
  --bs-btn-active-border-color: #191919;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #191919;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #191919;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #28a745;
  --bs-btn-border-color: #28a745;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #28a745;
  --bs-btn-hover-border-color: #28a745;
  --bs-btn-focus-shadow-rgb: 40, 167, 69;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #28a745;
  --bs-btn-active-border-color: #28a745;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #28a745;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #28a745;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #17a2b8;
  --bs-btn-hover-border-color: #17a2b8;
  --bs-btn-focus-shadow-rgb: 23, 162, 184;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #17a2b8;
  --bs-btn-active-border-color: #17a2b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #17a2b8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #17a2b8;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: #007bff;
  --bs-btn-border-color: #007bff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #007bff;
  --bs-btn-hover-border-color: #007bff;
  --bs-btn-focus-shadow-rgb: 0, 123, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #007bff;
  --bs-btn-active-border-color: #007bff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #007bff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #007bff;
  --bs-gradient: none;
}

.btn-outline-indigo {
  --bs-btn-color: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6610f2;
  --bs-btn-hover-border-color: #6610f2;
  --bs-btn-focus-shadow-rgb: 102, 16, 242;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6610f2;
  --bs-btn-active-border-color: #6610f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #6610f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6610f2;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #6f42c1;
  --bs-btn-border-color: #6f42c1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6f42c1;
  --bs-btn-hover-border-color: #6f42c1;
  --bs-btn-focus-shadow-rgb: 111, 66, 193;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6f42c1;
  --bs-btn-active-border-color: #6f42c1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #6f42c1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6f42c1;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #e83e8c;
  --bs-btn-border-color: #e83e8c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e83e8c;
  --bs-btn-hover-border-color: #e83e8c;
  --bs-btn-focus-shadow-rgb: 232, 62, 140;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e83e8c;
  --bs-btn-active-border-color: #e83e8c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #e83e8c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e83e8c;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fd7e14;
  --bs-btn-hover-border-color: #fd7e14;
  --bs-btn-focus-shadow-rgb: 253, 126, 20;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fd7e14;
  --bs-btn-active-border-color: #fd7e14;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fd7e14;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd7e14;
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #28a745;
  --bs-btn-border-color: #28a745;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #28a745;
  --bs-btn-hover-border-color: #28a745;
  --bs-btn-focus-shadow-rgb: 40, 167, 69;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #28a745;
  --bs-btn-active-border-color: #28a745;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #28a745;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #28a745;
  --bs-gradient: none;
}

.btn-outline-teal {
  --bs-btn-color: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #20c997;
  --bs-btn-hover-border-color: #20c997;
  --bs-btn-focus-shadow-rgb: 32, 201, 151;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #20c997;
  --bs-btn-active-border-color: #20c997;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #20c997;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #20c997;
  --bs-gradient: none;
}

.btn-outline-cyan {
  --bs-btn-color: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #17a2b8;
  --bs-btn-hover-border-color: #17a2b8;
  --bs-btn-focus-shadow-rgb: 23, 162, 184;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #17a2b8;
  --bs-btn-active-border-color: #17a2b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #17a2b8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #17a2b8;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #343a40;
  --bs-btn-hover-border-color: #343a40;
  --bs-btn-focus-shadow-rgb: 52, 58, 64;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #343a40;
  --bs-btn-active-border-color: #343a40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #343a40;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-gray-dark {
  --bs-btn-color: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #343a40;
  --bs-btn-hover-border-color: #343a40;
  --bs-btn-focus-shadow-rgb: 52, 58, 64;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #343a40;
  --bs-btn-active-border-color: #343a40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #343a40;
  --bs-gradient: none;
}

.btn-outline-blue-100 {
  --bs-btn-color: #cce5ff;
  --bs-btn-border-color: #cce5ff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #cce5ff;
  --bs-btn-hover-border-color: #cce5ff;
  --bs-btn-focus-shadow-rgb: 204, 229, 255;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #cce5ff;
  --bs-btn-active-border-color: #cce5ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #cce5ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cce5ff;
  --bs-gradient: none;
}

.btn-outline-blue-200 {
  --bs-btn-color: #99caff;
  --bs-btn-border-color: #99caff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #99caff;
  --bs-btn-hover-border-color: #99caff;
  --bs-btn-focus-shadow-rgb: 153, 202, 255;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #99caff;
  --bs-btn-active-border-color: #99caff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #99caff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #99caff;
  --bs-gradient: none;
}

.btn-outline-blue-300 {
  --bs-btn-color: #66b0ff;
  --bs-btn-border-color: #66b0ff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #66b0ff;
  --bs-btn-hover-border-color: #66b0ff;
  --bs-btn-focus-shadow-rgb: 102, 176, 255;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #66b0ff;
  --bs-btn-active-border-color: #66b0ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #66b0ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #66b0ff;
  --bs-gradient: none;
}

.btn-outline-blue-400 {
  --bs-btn-color: #3395ff;
  --bs-btn-border-color: #3395ff;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #3395ff;
  --bs-btn-hover-border-color: #3395ff;
  --bs-btn-focus-shadow-rgb: 51, 149, 255;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #3395ff;
  --bs-btn-active-border-color: #3395ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #3395ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3395ff;
  --bs-gradient: none;
}

.btn-outline-blue-500 {
  --bs-btn-color: #007bff;
  --bs-btn-border-color: #007bff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #007bff;
  --bs-btn-hover-border-color: #007bff;
  --bs-btn-focus-shadow-rgb: 0, 123, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #007bff;
  --bs-btn-active-border-color: #007bff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #007bff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #007bff;
  --bs-gradient: none;
}

.btn-outline-blue-600 {
  --bs-btn-color: #0062cc;
  --bs-btn-border-color: #0062cc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0062cc;
  --bs-btn-hover-border-color: #0062cc;
  --bs-btn-focus-shadow-rgb: 0, 98, 204;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0062cc;
  --bs-btn-active-border-color: #0062cc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #0062cc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0062cc;
  --bs-gradient: none;
}

.btn-outline-blue-700 {
  --bs-btn-color: #004a99;
  --bs-btn-border-color: #004a99;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004a99;
  --bs-btn-hover-border-color: #004a99;
  --bs-btn-focus-shadow-rgb: 0, 74, 153;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #004a99;
  --bs-btn-active-border-color: #004a99;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #004a99;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #004a99;
  --bs-gradient: none;
}

.btn-outline-blue-800 {
  --bs-btn-color: #003166;
  --bs-btn-border-color: #003166;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #003166;
  --bs-btn-hover-border-color: #003166;
  --bs-btn-focus-shadow-rgb: 0, 49, 102;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #003166;
  --bs-btn-active-border-color: #003166;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #003166;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #003166;
  --bs-gradient: none;
}

.btn-outline-blue-900 {
  --bs-btn-color: #001933;
  --bs-btn-border-color: #001933;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001933;
  --bs-btn-hover-border-color: #001933;
  --bs-btn-focus-shadow-rgb: 0, 25, 51;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001933;
  --bs-btn-active-border-color: #001933;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #001933;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #001933;
  --bs-gradient: none;
}

.btn-outline-indigo-100 {
  --bs-btn-color: #e0cffc;
  --bs-btn-border-color: #e0cffc;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #e0cffc;
  --bs-btn-hover-border-color: #e0cffc;
  --bs-btn-focus-shadow-rgb: 224, 207, 252;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #e0cffc;
  --bs-btn-active-border-color: #e0cffc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #e0cffc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e0cffc;
  --bs-gradient: none;
}

.btn-outline-indigo-200 {
  --bs-btn-color: #c29ffa;
  --bs-btn-border-color: #c29ffa;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #c29ffa;
  --bs-btn-hover-border-color: #c29ffa;
  --bs-btn-focus-shadow-rgb: 194, 159, 250;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #c29ffa;
  --bs-btn-active-border-color: #c29ffa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #c29ffa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c29ffa;
  --bs-gradient: none;
}

.btn-outline-indigo-300 {
  --bs-btn-color: #a370f7;
  --bs-btn-border-color: #a370f7;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #a370f7;
  --bs-btn-hover-border-color: #a370f7;
  --bs-btn-focus-shadow-rgb: 163, 112, 247;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #a370f7;
  --bs-btn-active-border-color: #a370f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #a370f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a370f7;
  --bs-gradient: none;
}

.btn-outline-indigo-400 {
  --bs-btn-color: #8540f5;
  --bs-btn-border-color: #8540f5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8540f5;
  --bs-btn-hover-border-color: #8540f5;
  --bs-btn-focus-shadow-rgb: 133, 64, 245;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8540f5;
  --bs-btn-active-border-color: #8540f5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #8540f5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8540f5;
  --bs-gradient: none;
}

.btn-outline-indigo-500 {
  --bs-btn-color: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6610f2;
  --bs-btn-hover-border-color: #6610f2;
  --bs-btn-focus-shadow-rgb: 102, 16, 242;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6610f2;
  --bs-btn-active-border-color: #6610f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #6610f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6610f2;
  --bs-gradient: none;
}

.btn-outline-indigo-600 {
  --bs-btn-color: #520dc2;
  --bs-btn-border-color: #520dc2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #520dc2;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 82, 13, 194;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #520dc2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #520dc2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #520dc2;
  --bs-gradient: none;
}

.btn-outline-indigo-700 {
  --bs-btn-color: #3d0a91;
  --bs-btn-border-color: #3d0a91;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3d0a91;
  --bs-btn-hover-border-color: #3d0a91;
  --bs-btn-focus-shadow-rgb: 61, 10, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3d0a91;
  --bs-btn-active-border-color: #3d0a91;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #3d0a91;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3d0a91;
  --bs-gradient: none;
}

.btn-outline-indigo-800 {
  --bs-btn-color: #290661;
  --bs-btn-border-color: #290661;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #290661;
  --bs-btn-hover-border-color: #290661;
  --bs-btn-focus-shadow-rgb: 41, 6, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #290661;
  --bs-btn-active-border-color: #290661;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #290661;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #290661;
  --bs-gradient: none;
}

.btn-outline-indigo-900 {
  --bs-btn-color: #140330;
  --bs-btn-border-color: #140330;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #140330;
  --bs-btn-hover-border-color: #140330;
  --bs-btn-focus-shadow-rgb: 20, 3, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #140330;
  --bs-btn-active-border-color: #140330;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #140330;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #140330;
  --bs-gradient: none;
}

.btn-outline-purple-100 {
  --bs-btn-color: #e2d9f3;
  --bs-btn-border-color: #e2d9f3;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #e2d9f3;
  --bs-btn-hover-border-color: #e2d9f3;
  --bs-btn-focus-shadow-rgb: 226, 217, 243;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #e2d9f3;
  --bs-btn-active-border-color: #e2d9f3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #e2d9f3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e2d9f3;
  --bs-gradient: none;
}

.btn-outline-purple-200 {
  --bs-btn-color: #c5b3e6;
  --bs-btn-border-color: #c5b3e6;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #c5b3e6;
  --bs-btn-hover-border-color: #c5b3e6;
  --bs-btn-focus-shadow-rgb: 197, 179, 230;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #c5b3e6;
  --bs-btn-active-border-color: #c5b3e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #c5b3e6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c5b3e6;
  --bs-gradient: none;
}

.btn-outline-purple-300 {
  --bs-btn-color: #a98eda;
  --bs-btn-border-color: #a98eda;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #a98eda;
  --bs-btn-hover-border-color: #a98eda;
  --bs-btn-focus-shadow-rgb: 169, 142, 218;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #a98eda;
  --bs-btn-active-border-color: #a98eda;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #a98eda;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a98eda;
  --bs-gradient: none;
}

.btn-outline-purple-400 {
  --bs-btn-color: #8c68cd;
  --bs-btn-border-color: #8c68cd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8c68cd;
  --bs-btn-hover-border-color: #8c68cd;
  --bs-btn-focus-shadow-rgb: 140, 104, 205;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8c68cd;
  --bs-btn-active-border-color: #8c68cd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #8c68cd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8c68cd;
  --bs-gradient: none;
}

.btn-outline-purple-500 {
  --bs-btn-color: #6f42c1;
  --bs-btn-border-color: #6f42c1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6f42c1;
  --bs-btn-hover-border-color: #6f42c1;
  --bs-btn-focus-shadow-rgb: 111, 66, 193;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6f42c1;
  --bs-btn-active-border-color: #6f42c1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #6f42c1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6f42c1;
  --bs-gradient: none;
}

.btn-outline-purple-600 {
  --bs-btn-color: #59359a;
  --bs-btn-border-color: #59359a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #59359a;
  --bs-btn-hover-border-color: #59359a;
  --bs-btn-focus-shadow-rgb: 89, 53, 154;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #59359a;
  --bs-btn-active-border-color: #59359a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #59359a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #59359a;
  --bs-gradient: none;
}

.btn-outline-purple-700 {
  --bs-btn-color: #432874;
  --bs-btn-border-color: #432874;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #432874;
  --bs-btn-hover-border-color: #432874;
  --bs-btn-focus-shadow-rgb: 67, 40, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #432874;
  --bs-btn-active-border-color: #432874;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #432874;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #432874;
  --bs-gradient: none;
}

.btn-outline-purple-800 {
  --bs-btn-color: #2c1a4d;
  --bs-btn-border-color: #2c1a4d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2c1a4d;
  --bs-btn-hover-border-color: #2c1a4d;
  --bs-btn-focus-shadow-rgb: 44, 26, 77;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c1a4d;
  --bs-btn-active-border-color: #2c1a4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #2c1a4d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2c1a4d;
  --bs-gradient: none;
}

.btn-outline-purple-900 {
  --bs-btn-color: #160d27;
  --bs-btn-border-color: #160d27;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #160d27;
  --bs-btn-hover-border-color: #160d27;
  --bs-btn-focus-shadow-rgb: 22, 13, 39;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #160d27;
  --bs-btn-active-border-color: #160d27;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #160d27;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #160d27;
  --bs-gradient: none;
}

.btn-outline-pink-100 {
  --bs-btn-color: #fad8e8;
  --bs-btn-border-color: #fad8e8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fad8e8;
  --bs-btn-hover-border-color: #fad8e8;
  --bs-btn-focus-shadow-rgb: 250, 216, 232;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fad8e8;
  --bs-btn-active-border-color: #fad8e8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fad8e8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fad8e8;
  --bs-gradient: none;
}

.btn-outline-pink-200 {
  --bs-btn-color: #f6b2d1;
  --bs-btn-border-color: #f6b2d1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f6b2d1;
  --bs-btn-hover-border-color: #f6b2d1;
  --bs-btn-focus-shadow-rgb: 246, 178, 209;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f6b2d1;
  --bs-btn-active-border-color: #f6b2d1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #f6b2d1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f6b2d1;
  --bs-gradient: none;
}

.btn-outline-pink-300 {
  --bs-btn-color: #f18bba;
  --bs-btn-border-color: #f18bba;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f18bba;
  --bs-btn-hover-border-color: #f18bba;
  --bs-btn-focus-shadow-rgb: 241, 139, 186;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f18bba;
  --bs-btn-active-border-color: #f18bba;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #f18bba;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f18bba;
  --bs-gradient: none;
}

.btn-outline-pink-400 {
  --bs-btn-color: #ed65a3;
  --bs-btn-border-color: #ed65a3;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ed65a3;
  --bs-btn-hover-border-color: #ed65a3;
  --bs-btn-focus-shadow-rgb: 237, 101, 163;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ed65a3;
  --bs-btn-active-border-color: #ed65a3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ed65a3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ed65a3;
  --bs-gradient: none;
}

.btn-outline-pink-500 {
  --bs-btn-color: #e83e8c;
  --bs-btn-border-color: #e83e8c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e83e8c;
  --bs-btn-hover-border-color: #e83e8c;
  --bs-btn-focus-shadow-rgb: 232, 62, 140;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e83e8c;
  --bs-btn-active-border-color: #e83e8c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #e83e8c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e83e8c;
  --bs-gradient: none;
}

.btn-outline-pink-600 {
  --bs-btn-color: #ba3270;
  --bs-btn-border-color: #ba3270;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ba3270;
  --bs-btn-hover-border-color: #ba3270;
  --bs-btn-focus-shadow-rgb: 186, 50, 112;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ba3270;
  --bs-btn-active-border-color: #ba3270;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ba3270;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ba3270;
  --bs-gradient: none;
}

.btn-outline-pink-700 {
  --bs-btn-color: #8b2554;
  --bs-btn-border-color: #8b2554;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8b2554;
  --bs-btn-hover-border-color: #8b2554;
  --bs-btn-focus-shadow-rgb: 139, 37, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8b2554;
  --bs-btn-active-border-color: #8b2554;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #8b2554;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8b2554;
  --bs-gradient: none;
}

.btn-outline-pink-800 {
  --bs-btn-color: #5d1938;
  --bs-btn-border-color: #5d1938;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5d1938;
  --bs-btn-hover-border-color: #5d1938;
  --bs-btn-focus-shadow-rgb: 93, 25, 56;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5d1938;
  --bs-btn-active-border-color: #5d1938;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #5d1938;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5d1938;
  --bs-gradient: none;
}

.btn-outline-pink-900 {
  --bs-btn-color: #2e0c1c;
  --bs-btn-border-color: #2e0c1c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2e0c1c;
  --bs-btn-hover-border-color: #2e0c1c;
  --bs-btn-focus-shadow-rgb: 46, 12, 28;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2e0c1c;
  --bs-btn-active-border-color: #2e0c1c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #2e0c1c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2e0c1c;
  --bs-gradient: none;
}

.btn-outline-red-100 {
  --bs-btn-color: #f8d7da;
  --bs-btn-border-color: #f8d7da;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f8d7da;
  --bs-btn-hover-border-color: #f8d7da;
  --bs-btn-focus-shadow-rgb: 248, 215, 218;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f8d7da;
  --bs-btn-active-border-color: #f8d7da;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #f8d7da;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8d7da;
  --bs-gradient: none;
}

.btn-outline-red-200 {
  --bs-btn-color: #f1aeb5;
  --bs-btn-border-color: #f1aeb5;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #f1aeb5;
  --bs-btn-hover-border-color: #f1aeb5;
  --bs-btn-focus-shadow-rgb: 241, 174, 181;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #f1aeb5;
  --bs-btn-active-border-color: #f1aeb5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #f1aeb5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f1aeb5;
  --bs-gradient: none;
}

.btn-outline-red-300 {
  --bs-btn-color: #ea868f;
  --bs-btn-border-color: #ea868f;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ea868f;
  --bs-btn-hover-border-color: #ea868f;
  --bs-btn-focus-shadow-rgb: 234, 134, 143;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ea868f;
  --bs-btn-active-border-color: #ea868f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ea868f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ea868f;
  --bs-gradient: none;
}

.btn-outline-red-400 {
  --bs-btn-color: #e35d6a;
  --bs-btn-border-color: #e35d6a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e35d6a;
  --bs-btn-hover-border-color: #e35d6a;
  --bs-btn-focus-shadow-rgb: 227, 93, 106;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e35d6a;
  --bs-btn-active-border-color: #e35d6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #e35d6a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e35d6a;
  --bs-gradient: none;
}

.btn-outline-red-500 {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-red-600 {
  --bs-btn-color: #b02a37;
  --bs-btn-border-color: #b02a37;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b02a37;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 176, 42, 55;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #b02a37;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #b02a37;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b02a37;
  --bs-gradient: none;
}

.btn-outline-red-700 {
  --bs-btn-color: #842029;
  --bs-btn-border-color: #842029;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #842029;
  --bs-btn-hover-border-color: #842029;
  --bs-btn-focus-shadow-rgb: 132, 32, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #842029;
  --bs-btn-active-border-color: #842029;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #842029;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #842029;
  --bs-gradient: none;
}

.btn-outline-red-800 {
  --bs-btn-color: #58151c;
  --bs-btn-border-color: #58151c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #58151c;
  --bs-btn-hover-border-color: #58151c;
  --bs-btn-focus-shadow-rgb: 88, 21, 28;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #58151c;
  --bs-btn-active-border-color: #58151c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #58151c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #58151c;
  --bs-gradient: none;
}

.btn-outline-red-900 {
  --bs-btn-color: #2c0b0e;
  --bs-btn-border-color: #2c0b0e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2c0b0e;
  --bs-btn-hover-border-color: #2c0b0e;
  --bs-btn-focus-shadow-rgb: 44, 11, 14;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c0b0e;
  --bs-btn-active-border-color: #2c0b0e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #2c0b0e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2c0b0e;
  --bs-gradient: none;
}

.btn-outline-orange-100 {
  --bs-btn-color: #ffe5d0;
  --bs-btn-border-color: #ffe5d0;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffe5d0;
  --bs-btn-hover-border-color: #ffe5d0;
  --bs-btn-focus-shadow-rgb: 255, 229, 208;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffe5d0;
  --bs-btn-active-border-color: #ffe5d0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ffe5d0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffe5d0;
  --bs-gradient: none;
}

.btn-outline-orange-200 {
  --bs-btn-color: #fecba1;
  --bs-btn-border-color: #fecba1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fecba1;
  --bs-btn-hover-border-color: #fecba1;
  --bs-btn-focus-shadow-rgb: 254, 203, 161;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fecba1;
  --bs-btn-active-border-color: #fecba1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fecba1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fecba1;
  --bs-gradient: none;
}

.btn-outline-orange-300 {
  --bs-btn-color: #feb272;
  --bs-btn-border-color: #feb272;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #feb272;
  --bs-btn-hover-border-color: #feb272;
  --bs-btn-focus-shadow-rgb: 254, 178, 114;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #feb272;
  --bs-btn-active-border-color: #feb272;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #feb272;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #feb272;
  --bs-gradient: none;
}

.btn-outline-orange-400 {
  --bs-btn-color: #fd9843;
  --bs-btn-border-color: #fd9843;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fd9843;
  --bs-btn-hover-border-color: #fd9843;
  --bs-btn-focus-shadow-rgb: 253, 152, 67;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fd9843;
  --bs-btn-active-border-color: #fd9843;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fd9843;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd9843;
  --bs-gradient: none;
}

.btn-outline-orange-500 {
  --bs-btn-color: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fd7e14;
  --bs-btn-hover-border-color: #fd7e14;
  --bs-btn-focus-shadow-rgb: 253, 126, 20;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fd7e14;
  --bs-btn-active-border-color: #fd7e14;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fd7e14;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd7e14;
  --bs-gradient: none;
}

.btn-outline-orange-600 {
  --bs-btn-color: #ca6510;
  --bs-btn-border-color: #ca6510;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ca6510;
  --bs-btn-hover-border-color: #ca6510;
  --bs-btn-focus-shadow-rgb: 202, 101, 16;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ca6510;
  --bs-btn-active-border-color: #ca6510;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ca6510;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ca6510;
  --bs-gradient: none;
}

.btn-outline-orange-700 {
  --bs-btn-color: #984c0c;
  --bs-btn-border-color: #984c0c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #984c0c;
  --bs-btn-hover-border-color: #984c0c;
  --bs-btn-focus-shadow-rgb: 152, 76, 12;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #984c0c;
  --bs-btn-active-border-color: #984c0c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #984c0c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #984c0c;
  --bs-gradient: none;
}

.btn-outline-orange-800 {
  --bs-btn-color: #653208;
  --bs-btn-border-color: #653208;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #653208;
  --bs-btn-hover-border-color: #653208;
  --bs-btn-focus-shadow-rgb: 101, 50, 8;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #653208;
  --bs-btn-active-border-color: #653208;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #653208;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #653208;
  --bs-gradient: none;
}

.btn-outline-orange-900 {
  --bs-btn-color: #331904;
  --bs-btn-border-color: #331904;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #331904;
  --bs-btn-hover-border-color: #331904;
  --bs-btn-focus-shadow-rgb: 51, 25, 4;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #331904;
  --bs-btn-active-border-color: #331904;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #331904;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #331904;
  --bs-gradient: none;
}

.btn-outline-yellow-100 {
  --bs-btn-color: #fff3cd;
  --bs-btn-border-color: #fff3cd;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fff3cd;
  --bs-btn-hover-border-color: #fff3cd;
  --bs-btn-focus-shadow-rgb: 255, 243, 205;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #fff3cd;
  --bs-btn-active-border-color: #fff3cd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #fff3cd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff3cd;
  --bs-gradient: none;
}

.btn-outline-yellow-200 {
  --bs-btn-color: #ffe69c;
  --bs-btn-border-color: #ffe69c;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffe69c;
  --bs-btn-hover-border-color: #ffe69c;
  --bs-btn-focus-shadow-rgb: 255, 230, 156;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffe69c;
  --bs-btn-active-border-color: #ffe69c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ffe69c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffe69c;
  --bs-gradient: none;
}

.btn-outline-yellow-300 {
  --bs-btn-color: #ffda6a;
  --bs-btn-border-color: #ffda6a;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffda6a;
  --bs-btn-hover-border-color: #ffda6a;
  --bs-btn-focus-shadow-rgb: 255, 218, 106;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffda6a;
  --bs-btn-active-border-color: #ffda6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ffda6a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffda6a;
  --bs-gradient: none;
}

.btn-outline-yellow-400 {
  --bs-btn-color: #ffcd39;
  --bs-btn-border-color: #ffcd39;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffcd39;
  --bs-btn-hover-border-color: #ffcd39;
  --bs-btn-focus-shadow-rgb: 255, 205, 57;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffcd39;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ffcd39;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffcd39;
  --bs-gradient: none;
}

.btn-outline-yellow-500 {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-yellow-600 {
  --bs-btn-color: #cc9a06;
  --bs-btn-border-color: #cc9a06;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #cc9a06;
  --bs-btn-hover-border-color: #cc9a06;
  --bs-btn-focus-shadow-rgb: 204, 154, 6;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #cc9a06;
  --bs-btn-active-border-color: #cc9a06;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #cc9a06;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cc9a06;
  --bs-gradient: none;
}

.btn-outline-yellow-700 {
  --bs-btn-color: #997404;
  --bs-btn-border-color: #997404;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #997404;
  --bs-btn-hover-border-color: #997404;
  --bs-btn-focus-shadow-rgb: 153, 116, 4;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #997404;
  --bs-btn-active-border-color: #997404;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #997404;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #997404;
  --bs-gradient: none;
}

.btn-outline-yellow-800 {
  --bs-btn-color: #664d03;
  --bs-btn-border-color: #664d03;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #664d03;
  --bs-btn-hover-border-color: #664d03;
  --bs-btn-focus-shadow-rgb: 102, 77, 3;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #664d03;
  --bs-btn-active-border-color: #664d03;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #664d03;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #664d03;
  --bs-gradient: none;
}

.btn-outline-yellow-900 {
  --bs-btn-color: #332701;
  --bs-btn-border-color: #332701;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #332701;
  --bs-btn-hover-border-color: #332701;
  --bs-btn-focus-shadow-rgb: 51, 39, 1;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #332701;
  --bs-btn-active-border-color: #332701;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #332701;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #332701;
  --bs-gradient: none;
}

.btn-outline-green-100 {
  --bs-btn-color: #d4edda;
  --bs-btn-border-color: #d4edda;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d4edda;
  --bs-btn-hover-border-color: #d4edda;
  --bs-btn-focus-shadow-rgb: 212, 237, 218;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #d4edda;
  --bs-btn-active-border-color: #d4edda;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #d4edda;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d4edda;
  --bs-gradient: none;
}

.btn-outline-green-200 {
  --bs-btn-color: #a9dcb5;
  --bs-btn-border-color: #a9dcb5;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #a9dcb5;
  --bs-btn-hover-border-color: #a9dcb5;
  --bs-btn-focus-shadow-rgb: 169, 220, 181;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #a9dcb5;
  --bs-btn-active-border-color: #a9dcb5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #a9dcb5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a9dcb5;
  --bs-gradient: none;
}

.btn-outline-green-300 {
  --bs-btn-color: #7eca8f;
  --bs-btn-border-color: #7eca8f;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #7eca8f;
  --bs-btn-hover-border-color: #7eca8f;
  --bs-btn-focus-shadow-rgb: 126, 202, 143;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #7eca8f;
  --bs-btn-active-border-color: #7eca8f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #7eca8f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7eca8f;
  --bs-gradient: none;
}

.btn-outline-green-400 {
  --bs-btn-color: #53b96a;
  --bs-btn-border-color: #53b96a;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #53b96a;
  --bs-btn-hover-border-color: #53b96a;
  --bs-btn-focus-shadow-rgb: 83, 185, 106;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #53b96a;
  --bs-btn-active-border-color: #53b96a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #53b96a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #53b96a;
  --bs-gradient: none;
}

.btn-outline-green-500 {
  --bs-btn-color: #28a745;
  --bs-btn-border-color: #28a745;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #28a745;
  --bs-btn-hover-border-color: #28a745;
  --bs-btn-focus-shadow-rgb: 40, 167, 69;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #28a745;
  --bs-btn-active-border-color: #28a745;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #28a745;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #28a745;
  --bs-gradient: none;
}

.btn-outline-green-600 {
  --bs-btn-color: #208637;
  --bs-btn-border-color: #208637;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #208637;
  --bs-btn-hover-border-color: #208637;
  --bs-btn-focus-shadow-rgb: 32, 134, 55;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #208637;
  --bs-btn-active-border-color: #208637;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #208637;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #208637;
  --bs-gradient: none;
}

.btn-outline-green-700 {
  --bs-btn-color: #186429;
  --bs-btn-border-color: #186429;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #186429;
  --bs-btn-hover-border-color: #186429;
  --bs-btn-focus-shadow-rgb: 24, 100, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #186429;
  --bs-btn-active-border-color: #186429;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #186429;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #186429;
  --bs-gradient: none;
}

.btn-outline-green-800 {
  --bs-btn-color: #10431c;
  --bs-btn-border-color: #10431c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #10431c;
  --bs-btn-hover-border-color: #10431c;
  --bs-btn-focus-shadow-rgb: 16, 67, 28;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #10431c;
  --bs-btn-active-border-color: #10431c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #10431c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #10431c;
  --bs-gradient: none;
}

.btn-outline-green-900 {
  --bs-btn-color: #08210e;
  --bs-btn-border-color: #08210e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #08210e;
  --bs-btn-hover-border-color: #08210e;
  --bs-btn-focus-shadow-rgb: 8, 33, 14;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #08210e;
  --bs-btn-active-border-color: #08210e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #08210e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #08210e;
  --bs-gradient: none;
}

.btn-outline-teal-100 {
  --bs-btn-color: #d2f4ea;
  --bs-btn-border-color: #d2f4ea;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d2f4ea;
  --bs-btn-hover-border-color: #d2f4ea;
  --bs-btn-focus-shadow-rgb: 210, 244, 234;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #d2f4ea;
  --bs-btn-active-border-color: #d2f4ea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #d2f4ea;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d2f4ea;
  --bs-gradient: none;
}

.btn-outline-teal-200 {
  --bs-btn-color: #a6e9d5;
  --bs-btn-border-color: #a6e9d5;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #a6e9d5;
  --bs-btn-hover-border-color: #a6e9d5;
  --bs-btn-focus-shadow-rgb: 166, 233, 213;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #a6e9d5;
  --bs-btn-active-border-color: #a6e9d5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #a6e9d5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a6e9d5;
  --bs-gradient: none;
}

.btn-outline-teal-300 {
  --bs-btn-color: #79dfc1;
  --bs-btn-border-color: #79dfc1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #79dfc1;
  --bs-btn-hover-border-color: #79dfc1;
  --bs-btn-focus-shadow-rgb: 121, 223, 193;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #79dfc1;
  --bs-btn-active-border-color: #79dfc1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #79dfc1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #79dfc1;
  --bs-gradient: none;
}

.btn-outline-teal-400 {
  --bs-btn-color: #4dd4ac;
  --bs-btn-border-color: #4dd4ac;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #4dd4ac;
  --bs-btn-hover-border-color: #4dd4ac;
  --bs-btn-focus-shadow-rgb: 77, 212, 172;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #4dd4ac;
  --bs-btn-active-border-color: #4dd4ac;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #4dd4ac;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4dd4ac;
  --bs-gradient: none;
}

.btn-outline-teal-500 {
  --bs-btn-color: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #20c997;
  --bs-btn-hover-border-color: #20c997;
  --bs-btn-focus-shadow-rgb: 32, 201, 151;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #20c997;
  --bs-btn-active-border-color: #20c997;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #20c997;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #20c997;
  --bs-gradient: none;
}

.btn-outline-teal-600 {
  --bs-btn-color: #1aa179;
  --bs-btn-border-color: #1aa179;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #1aa179;
  --bs-btn-hover-border-color: #1aa179;
  --bs-btn-focus-shadow-rgb: 26, 161, 121;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #1aa179;
  --bs-btn-active-border-color: #1aa179;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #1aa179;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1aa179;
  --bs-gradient: none;
}

.btn-outline-teal-700 {
  --bs-btn-color: #13795b;
  --bs-btn-border-color: #13795b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #13795b;
  --bs-btn-hover-border-color: #13795b;
  --bs-btn-focus-shadow-rgb: 19, 121, 91;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #13795b;
  --bs-btn-active-border-color: #13795b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #13795b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #13795b;
  --bs-gradient: none;
}

.btn-outline-teal-800 {
  --bs-btn-color: #0d503c;
  --bs-btn-border-color: #0d503c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d503c;
  --bs-btn-hover-border-color: #0d503c;
  --bs-btn-focus-shadow-rgb: 13, 80, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d503c;
  --bs-btn-active-border-color: #0d503c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #0d503c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d503c;
  --bs-gradient: none;
}

.btn-outline-teal-900 {
  --bs-btn-color: #06281e;
  --bs-btn-border-color: #06281e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #06281e;
  --bs-btn-hover-border-color: #06281e;
  --bs-btn-focus-shadow-rgb: 6, 40, 30;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #06281e;
  --bs-btn-active-border-color: #06281e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #06281e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #06281e;
  --bs-gradient: none;
}

.btn-outline-cyan-100 {
  --bs-btn-color: #d1ecf1;
  --bs-btn-border-color: #d1ecf1;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #d1ecf1;
  --bs-btn-hover-border-color: #d1ecf1;
  --bs-btn-focus-shadow-rgb: 209, 236, 241;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #d1ecf1;
  --bs-btn-active-border-color: #d1ecf1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #d1ecf1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d1ecf1;
  --bs-gradient: none;
}

.btn-outline-cyan-200 {
  --bs-btn-color: #a2dae3;
  --bs-btn-border-color: #a2dae3;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #a2dae3;
  --bs-btn-hover-border-color: #a2dae3;
  --bs-btn-focus-shadow-rgb: 162, 218, 227;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #a2dae3;
  --bs-btn-active-border-color: #a2dae3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #a2dae3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a2dae3;
  --bs-gradient: none;
}

.btn-outline-cyan-300 {
  --bs-btn-color: #74c7d4;
  --bs-btn-border-color: #74c7d4;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #74c7d4;
  --bs-btn-hover-border-color: #74c7d4;
  --bs-btn-focus-shadow-rgb: 116, 199, 212;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #74c7d4;
  --bs-btn-active-border-color: #74c7d4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #74c7d4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #74c7d4;
  --bs-gradient: none;
}

.btn-outline-cyan-400 {
  --bs-btn-color: #45b5c6;
  --bs-btn-border-color: #45b5c6;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #45b5c6;
  --bs-btn-hover-border-color: #45b5c6;
  --bs-btn-focus-shadow-rgb: 69, 181, 198;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #45b5c6;
  --bs-btn-active-border-color: #45b5c6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #45b5c6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #45b5c6;
  --bs-gradient: none;
}

.btn-outline-cyan-500 {
  --bs-btn-color: #17a2b8;
  --bs-btn-border-color: #17a2b8;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #17a2b8;
  --bs-btn-hover-border-color: #17a2b8;
  --bs-btn-focus-shadow-rgb: 23, 162, 184;
  --bs-btn-active-color: #343a40;
  --bs-btn-active-bg: #17a2b8;
  --bs-btn-active-border-color: #17a2b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #17a2b8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #17a2b8;
  --bs-gradient: none;
}

.btn-outline-cyan-600 {
  --bs-btn-color: #128293;
  --bs-btn-border-color: #128293;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #128293;
  --bs-btn-hover-border-color: #128293;
  --bs-btn-focus-shadow-rgb: 18, 130, 147;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #128293;
  --bs-btn-active-border-color: #128293;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #128293;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #128293;
  --bs-gradient: none;
}

.btn-outline-cyan-700 {
  --bs-btn-color: #0e616e;
  --bs-btn-border-color: #0e616e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0e616e;
  --bs-btn-hover-border-color: #0e616e;
  --bs-btn-focus-shadow-rgb: 14, 97, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e616e;
  --bs-btn-active-border-color: #0e616e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #0e616e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0e616e;
  --bs-gradient: none;
}

.btn-outline-cyan-800 {
  --bs-btn-color: #09414a;
  --bs-btn-border-color: #09414a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #09414a;
  --bs-btn-hover-border-color: #09414a;
  --bs-btn-focus-shadow-rgb: 9, 65, 74;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #09414a;
  --bs-btn-active-border-color: #09414a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #09414a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #09414a;
  --bs-gradient: none;
}

.btn-outline-cyan-900 {
  --bs-btn-color: #052025;
  --bs-btn-border-color: #052025;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #052025;
  --bs-btn-hover-border-color: #052025;
  --bs-btn-focus-shadow-rgb: 5, 32, 37;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #052025;
  --bs-btn-active-border-color: #052025;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(52, 58, 64, 0.125);
  --bs-btn-disabled-color: #052025;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #052025;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 147, 191, 95;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(0.375rem - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #a4d764;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #a4d764;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #a4d764;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text);
}

.alert-blue {
  --bs-alert-color: var(--bs-blue-text);
  --bs-alert-bg: var(--bs-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-border-subtle);
  --bs-alert-link-color: var(--bs-blue-text);
}

.alert-indigo {
  --bs-alert-color: var(--bs-indigo-text);
  --bs-alert-bg: var(--bs-indigo-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-text);
}

.alert-purple {
  --bs-alert-color: var(--bs-purple-text);
  --bs-alert-bg: var(--bs-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-border-subtle);
  --bs-alert-link-color: var(--bs-purple-text);
}

.alert-pink {
  --bs-alert-color: var(--bs-pink-text);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text);
}

.alert-red {
  --bs-alert-color: var(--bs-red-text);
  --bs-alert-bg: var(--bs-red-bg-subtle);
  --bs-alert-border-color: var(--bs-red-border-subtle);
  --bs-alert-link-color: var(--bs-red-text);
}

.alert-orange {
  --bs-alert-color: var(--bs-orange-text);
  --bs-alert-bg: var(--bs-orange-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-border-subtle);
  --bs-alert-link-color: var(--bs-orange-text);
}

.alert-yellow {
  --bs-alert-color: var(--bs-yellow-text);
  --bs-alert-bg: var(--bs-yellow-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-text);
}

.alert-green {
  --bs-alert-color: var(--bs-green-text);
  --bs-alert-bg: var(--bs-green-bg-subtle);
  --bs-alert-border-color: var(--bs-green-border-subtle);
  --bs-alert-link-color: var(--bs-green-text);
}

.alert-teal {
  --bs-alert-color: var(--bs-teal-text);
  --bs-alert-bg: var(--bs-teal-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-border-subtle);
  --bs-alert-link-color: var(--bs-teal-text);
}

.alert-cyan {
  --bs-alert-color: var(--bs-cyan-text);
  --bs-alert-bg: var(--bs-cyan-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-text);
}

.alert-black {
  --bs-alert-color: var(--bs-black-text);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text);
}

.alert-white {
  --bs-alert-color: var(--bs-white-text);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text);
}

.alert-gray {
  --bs-alert-color: var(--bs-gray-text);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text);
}

.alert-gray-dark {
  --bs-alert-color: var(--bs-gray-dark-text);
  --bs-alert-bg: var(--bs-gray-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-dark-border-subtle);
  --bs-alert-link-color: var(--bs-gray-dark-text);
}

.alert-blue-100 {
  --bs-alert-color: var(--bs-blue-100-text);
  --bs-alert-bg: var(--bs-blue-100-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-100-border-subtle);
  --bs-alert-link-color: var(--bs-blue-100-text);
}

.alert-blue-200 {
  --bs-alert-color: var(--bs-blue-200-text);
  --bs-alert-bg: var(--bs-blue-200-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-200-border-subtle);
  --bs-alert-link-color: var(--bs-blue-200-text);
}

.alert-blue-300 {
  --bs-alert-color: var(--bs-blue-300-text);
  --bs-alert-bg: var(--bs-blue-300-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-300-border-subtle);
  --bs-alert-link-color: var(--bs-blue-300-text);
}

.alert-blue-400 {
  --bs-alert-color: var(--bs-blue-400-text);
  --bs-alert-bg: var(--bs-blue-400-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-400-border-subtle);
  --bs-alert-link-color: var(--bs-blue-400-text);
}

.alert-blue-500 {
  --bs-alert-color: var(--bs-blue-500-text);
  --bs-alert-bg: var(--bs-blue-500-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-500-border-subtle);
  --bs-alert-link-color: var(--bs-blue-500-text);
}

.alert-blue-600 {
  --bs-alert-color: var(--bs-blue-600-text);
  --bs-alert-bg: var(--bs-blue-600-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-600-border-subtle);
  --bs-alert-link-color: var(--bs-blue-600-text);
}

.alert-blue-700 {
  --bs-alert-color: var(--bs-blue-700-text);
  --bs-alert-bg: var(--bs-blue-700-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-700-border-subtle);
  --bs-alert-link-color: var(--bs-blue-700-text);
}

.alert-blue-800 {
  --bs-alert-color: var(--bs-blue-800-text);
  --bs-alert-bg: var(--bs-blue-800-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-800-border-subtle);
  --bs-alert-link-color: var(--bs-blue-800-text);
}

.alert-blue-900 {
  --bs-alert-color: var(--bs-blue-900-text);
  --bs-alert-bg: var(--bs-blue-900-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-900-border-subtle);
  --bs-alert-link-color: var(--bs-blue-900-text);
}

.alert-indigo-100 {
  --bs-alert-color: var(--bs-indigo-100-text);
  --bs-alert-bg: var(--bs-indigo-100-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-100-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-100-text);
}

.alert-indigo-200 {
  --bs-alert-color: var(--bs-indigo-200-text);
  --bs-alert-bg: var(--bs-indigo-200-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-200-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-200-text);
}

.alert-indigo-300 {
  --bs-alert-color: var(--bs-indigo-300-text);
  --bs-alert-bg: var(--bs-indigo-300-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-300-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-300-text);
}

.alert-indigo-400 {
  --bs-alert-color: var(--bs-indigo-400-text);
  --bs-alert-bg: var(--bs-indigo-400-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-400-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-400-text);
}

.alert-indigo-500 {
  --bs-alert-color: var(--bs-indigo-500-text);
  --bs-alert-bg: var(--bs-indigo-500-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-500-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-500-text);
}

.alert-indigo-600 {
  --bs-alert-color: var(--bs-indigo-600-text);
  --bs-alert-bg: var(--bs-indigo-600-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-600-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-600-text);
}

.alert-indigo-700 {
  --bs-alert-color: var(--bs-indigo-700-text);
  --bs-alert-bg: var(--bs-indigo-700-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-700-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-700-text);
}

.alert-indigo-800 {
  --bs-alert-color: var(--bs-indigo-800-text);
  --bs-alert-bg: var(--bs-indigo-800-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-800-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-800-text);
}

.alert-indigo-900 {
  --bs-alert-color: var(--bs-indigo-900-text);
  --bs-alert-bg: var(--bs-indigo-900-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-900-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-900-text);
}

.alert-purple-100 {
  --bs-alert-color: var(--bs-purple-100-text);
  --bs-alert-bg: var(--bs-purple-100-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-100-border-subtle);
  --bs-alert-link-color: var(--bs-purple-100-text);
}

.alert-purple-200 {
  --bs-alert-color: var(--bs-purple-200-text);
  --bs-alert-bg: var(--bs-purple-200-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-200-border-subtle);
  --bs-alert-link-color: var(--bs-purple-200-text);
}

.alert-purple-300 {
  --bs-alert-color: var(--bs-purple-300-text);
  --bs-alert-bg: var(--bs-purple-300-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-300-border-subtle);
  --bs-alert-link-color: var(--bs-purple-300-text);
}

.alert-purple-400 {
  --bs-alert-color: var(--bs-purple-400-text);
  --bs-alert-bg: var(--bs-purple-400-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-400-border-subtle);
  --bs-alert-link-color: var(--bs-purple-400-text);
}

.alert-purple-500 {
  --bs-alert-color: var(--bs-purple-500-text);
  --bs-alert-bg: var(--bs-purple-500-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-500-border-subtle);
  --bs-alert-link-color: var(--bs-purple-500-text);
}

.alert-purple-600 {
  --bs-alert-color: var(--bs-purple-600-text);
  --bs-alert-bg: var(--bs-purple-600-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-600-border-subtle);
  --bs-alert-link-color: var(--bs-purple-600-text);
}

.alert-purple-700 {
  --bs-alert-color: var(--bs-purple-700-text);
  --bs-alert-bg: var(--bs-purple-700-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-700-border-subtle);
  --bs-alert-link-color: var(--bs-purple-700-text);
}

.alert-purple-800 {
  --bs-alert-color: var(--bs-purple-800-text);
  --bs-alert-bg: var(--bs-purple-800-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-800-border-subtle);
  --bs-alert-link-color: var(--bs-purple-800-text);
}

.alert-purple-900 {
  --bs-alert-color: var(--bs-purple-900-text);
  --bs-alert-bg: var(--bs-purple-900-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-900-border-subtle);
  --bs-alert-link-color: var(--bs-purple-900-text);
}

.alert-pink-100 {
  --bs-alert-color: var(--bs-pink-100-text);
  --bs-alert-bg: var(--bs-pink-100-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-100-border-subtle);
  --bs-alert-link-color: var(--bs-pink-100-text);
}

.alert-pink-200 {
  --bs-alert-color: var(--bs-pink-200-text);
  --bs-alert-bg: var(--bs-pink-200-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-200-border-subtle);
  --bs-alert-link-color: var(--bs-pink-200-text);
}

.alert-pink-300 {
  --bs-alert-color: var(--bs-pink-300-text);
  --bs-alert-bg: var(--bs-pink-300-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-300-border-subtle);
  --bs-alert-link-color: var(--bs-pink-300-text);
}

.alert-pink-400 {
  --bs-alert-color: var(--bs-pink-400-text);
  --bs-alert-bg: var(--bs-pink-400-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-400-border-subtle);
  --bs-alert-link-color: var(--bs-pink-400-text);
}

.alert-pink-500 {
  --bs-alert-color: var(--bs-pink-500-text);
  --bs-alert-bg: var(--bs-pink-500-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-500-border-subtle);
  --bs-alert-link-color: var(--bs-pink-500-text);
}

.alert-pink-600 {
  --bs-alert-color: var(--bs-pink-600-text);
  --bs-alert-bg: var(--bs-pink-600-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-600-border-subtle);
  --bs-alert-link-color: var(--bs-pink-600-text);
}

.alert-pink-700 {
  --bs-alert-color: var(--bs-pink-700-text);
  --bs-alert-bg: var(--bs-pink-700-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-700-border-subtle);
  --bs-alert-link-color: var(--bs-pink-700-text);
}

.alert-pink-800 {
  --bs-alert-color: var(--bs-pink-800-text);
  --bs-alert-bg: var(--bs-pink-800-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-800-border-subtle);
  --bs-alert-link-color: var(--bs-pink-800-text);
}

.alert-pink-900 {
  --bs-alert-color: var(--bs-pink-900-text);
  --bs-alert-bg: var(--bs-pink-900-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-900-border-subtle);
  --bs-alert-link-color: var(--bs-pink-900-text);
}

.alert-red-100 {
  --bs-alert-color: var(--bs-red-100-text);
  --bs-alert-bg: var(--bs-red-100-bg-subtle);
  --bs-alert-border-color: var(--bs-red-100-border-subtle);
  --bs-alert-link-color: var(--bs-red-100-text);
}

.alert-red-200 {
  --bs-alert-color: var(--bs-red-200-text);
  --bs-alert-bg: var(--bs-red-200-bg-subtle);
  --bs-alert-border-color: var(--bs-red-200-border-subtle);
  --bs-alert-link-color: var(--bs-red-200-text);
}

.alert-red-300 {
  --bs-alert-color: var(--bs-red-300-text);
  --bs-alert-bg: var(--bs-red-300-bg-subtle);
  --bs-alert-border-color: var(--bs-red-300-border-subtle);
  --bs-alert-link-color: var(--bs-red-300-text);
}

.alert-red-400 {
  --bs-alert-color: var(--bs-red-400-text);
  --bs-alert-bg: var(--bs-red-400-bg-subtle);
  --bs-alert-border-color: var(--bs-red-400-border-subtle);
  --bs-alert-link-color: var(--bs-red-400-text);
}

.alert-red-500 {
  --bs-alert-color: var(--bs-red-500-text);
  --bs-alert-bg: var(--bs-red-500-bg-subtle);
  --bs-alert-border-color: var(--bs-red-500-border-subtle);
  --bs-alert-link-color: var(--bs-red-500-text);
}

.alert-red-600 {
  --bs-alert-color: var(--bs-red-600-text);
  --bs-alert-bg: var(--bs-red-600-bg-subtle);
  --bs-alert-border-color: var(--bs-red-600-border-subtle);
  --bs-alert-link-color: var(--bs-red-600-text);
}

.alert-red-700 {
  --bs-alert-color: var(--bs-red-700-text);
  --bs-alert-bg: var(--bs-red-700-bg-subtle);
  --bs-alert-border-color: var(--bs-red-700-border-subtle);
  --bs-alert-link-color: var(--bs-red-700-text);
}

.alert-red-800 {
  --bs-alert-color: var(--bs-red-800-text);
  --bs-alert-bg: var(--bs-red-800-bg-subtle);
  --bs-alert-border-color: var(--bs-red-800-border-subtle);
  --bs-alert-link-color: var(--bs-red-800-text);
}

.alert-red-900 {
  --bs-alert-color: var(--bs-red-900-text);
  --bs-alert-bg: var(--bs-red-900-bg-subtle);
  --bs-alert-border-color: var(--bs-red-900-border-subtle);
  --bs-alert-link-color: var(--bs-red-900-text);
}

.alert-orange-100 {
  --bs-alert-color: var(--bs-orange-100-text);
  --bs-alert-bg: var(--bs-orange-100-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-100-border-subtle);
  --bs-alert-link-color: var(--bs-orange-100-text);
}

.alert-orange-200 {
  --bs-alert-color: var(--bs-orange-200-text);
  --bs-alert-bg: var(--bs-orange-200-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-200-border-subtle);
  --bs-alert-link-color: var(--bs-orange-200-text);
}

.alert-orange-300 {
  --bs-alert-color: var(--bs-orange-300-text);
  --bs-alert-bg: var(--bs-orange-300-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-300-border-subtle);
  --bs-alert-link-color: var(--bs-orange-300-text);
}

.alert-orange-400 {
  --bs-alert-color: var(--bs-orange-400-text);
  --bs-alert-bg: var(--bs-orange-400-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-400-border-subtle);
  --bs-alert-link-color: var(--bs-orange-400-text);
}

.alert-orange-500 {
  --bs-alert-color: var(--bs-orange-500-text);
  --bs-alert-bg: var(--bs-orange-500-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-500-border-subtle);
  --bs-alert-link-color: var(--bs-orange-500-text);
}

.alert-orange-600 {
  --bs-alert-color: var(--bs-orange-600-text);
  --bs-alert-bg: var(--bs-orange-600-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-600-border-subtle);
  --bs-alert-link-color: var(--bs-orange-600-text);
}

.alert-orange-700 {
  --bs-alert-color: var(--bs-orange-700-text);
  --bs-alert-bg: var(--bs-orange-700-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-700-border-subtle);
  --bs-alert-link-color: var(--bs-orange-700-text);
}

.alert-orange-800 {
  --bs-alert-color: var(--bs-orange-800-text);
  --bs-alert-bg: var(--bs-orange-800-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-800-border-subtle);
  --bs-alert-link-color: var(--bs-orange-800-text);
}

.alert-orange-900 {
  --bs-alert-color: var(--bs-orange-900-text);
  --bs-alert-bg: var(--bs-orange-900-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-900-border-subtle);
  --bs-alert-link-color: var(--bs-orange-900-text);
}

.alert-yellow-100 {
  --bs-alert-color: var(--bs-yellow-100-text);
  --bs-alert-bg: var(--bs-yellow-100-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-100-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-100-text);
}

.alert-yellow-200 {
  --bs-alert-color: var(--bs-yellow-200-text);
  --bs-alert-bg: var(--bs-yellow-200-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-200-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-200-text);
}

.alert-yellow-300 {
  --bs-alert-color: var(--bs-yellow-300-text);
  --bs-alert-bg: var(--bs-yellow-300-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-300-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-300-text);
}

.alert-yellow-400 {
  --bs-alert-color: var(--bs-yellow-400-text);
  --bs-alert-bg: var(--bs-yellow-400-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-400-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-400-text);
}

.alert-yellow-500 {
  --bs-alert-color: var(--bs-yellow-500-text);
  --bs-alert-bg: var(--bs-yellow-500-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-500-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-500-text);
}

.alert-yellow-600 {
  --bs-alert-color: var(--bs-yellow-600-text);
  --bs-alert-bg: var(--bs-yellow-600-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-600-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-600-text);
}

.alert-yellow-700 {
  --bs-alert-color: var(--bs-yellow-700-text);
  --bs-alert-bg: var(--bs-yellow-700-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-700-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-700-text);
}

.alert-yellow-800 {
  --bs-alert-color: var(--bs-yellow-800-text);
  --bs-alert-bg: var(--bs-yellow-800-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-800-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-800-text);
}

.alert-yellow-900 {
  --bs-alert-color: var(--bs-yellow-900-text);
  --bs-alert-bg: var(--bs-yellow-900-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-900-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-900-text);
}

.alert-green-100 {
  --bs-alert-color: var(--bs-green-100-text);
  --bs-alert-bg: var(--bs-green-100-bg-subtle);
  --bs-alert-border-color: var(--bs-green-100-border-subtle);
  --bs-alert-link-color: var(--bs-green-100-text);
}

.alert-green-200 {
  --bs-alert-color: var(--bs-green-200-text);
  --bs-alert-bg: var(--bs-green-200-bg-subtle);
  --bs-alert-border-color: var(--bs-green-200-border-subtle);
  --bs-alert-link-color: var(--bs-green-200-text);
}

.alert-green-300 {
  --bs-alert-color: var(--bs-green-300-text);
  --bs-alert-bg: var(--bs-green-300-bg-subtle);
  --bs-alert-border-color: var(--bs-green-300-border-subtle);
  --bs-alert-link-color: var(--bs-green-300-text);
}

.alert-green-400 {
  --bs-alert-color: var(--bs-green-400-text);
  --bs-alert-bg: var(--bs-green-400-bg-subtle);
  --bs-alert-border-color: var(--bs-green-400-border-subtle);
  --bs-alert-link-color: var(--bs-green-400-text);
}

.alert-green-500 {
  --bs-alert-color: var(--bs-green-500-text);
  --bs-alert-bg: var(--bs-green-500-bg-subtle);
  --bs-alert-border-color: var(--bs-green-500-border-subtle);
  --bs-alert-link-color: var(--bs-green-500-text);
}

.alert-green-600 {
  --bs-alert-color: var(--bs-green-600-text);
  --bs-alert-bg: var(--bs-green-600-bg-subtle);
  --bs-alert-border-color: var(--bs-green-600-border-subtle);
  --bs-alert-link-color: var(--bs-green-600-text);
}

.alert-green-700 {
  --bs-alert-color: var(--bs-green-700-text);
  --bs-alert-bg: var(--bs-green-700-bg-subtle);
  --bs-alert-border-color: var(--bs-green-700-border-subtle);
  --bs-alert-link-color: var(--bs-green-700-text);
}

.alert-green-800 {
  --bs-alert-color: var(--bs-green-800-text);
  --bs-alert-bg: var(--bs-green-800-bg-subtle);
  --bs-alert-border-color: var(--bs-green-800-border-subtle);
  --bs-alert-link-color: var(--bs-green-800-text);
}

.alert-green-900 {
  --bs-alert-color: var(--bs-green-900-text);
  --bs-alert-bg: var(--bs-green-900-bg-subtle);
  --bs-alert-border-color: var(--bs-green-900-border-subtle);
  --bs-alert-link-color: var(--bs-green-900-text);
}

.alert-teal-100 {
  --bs-alert-color: var(--bs-teal-100-text);
  --bs-alert-bg: var(--bs-teal-100-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-100-border-subtle);
  --bs-alert-link-color: var(--bs-teal-100-text);
}

.alert-teal-200 {
  --bs-alert-color: var(--bs-teal-200-text);
  --bs-alert-bg: var(--bs-teal-200-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-200-border-subtle);
  --bs-alert-link-color: var(--bs-teal-200-text);
}

.alert-teal-300 {
  --bs-alert-color: var(--bs-teal-300-text);
  --bs-alert-bg: var(--bs-teal-300-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-300-border-subtle);
  --bs-alert-link-color: var(--bs-teal-300-text);
}

.alert-teal-400 {
  --bs-alert-color: var(--bs-teal-400-text);
  --bs-alert-bg: var(--bs-teal-400-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-400-border-subtle);
  --bs-alert-link-color: var(--bs-teal-400-text);
}

.alert-teal-500 {
  --bs-alert-color: var(--bs-teal-500-text);
  --bs-alert-bg: var(--bs-teal-500-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-500-border-subtle);
  --bs-alert-link-color: var(--bs-teal-500-text);
}

.alert-teal-600 {
  --bs-alert-color: var(--bs-teal-600-text);
  --bs-alert-bg: var(--bs-teal-600-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-600-border-subtle);
  --bs-alert-link-color: var(--bs-teal-600-text);
}

.alert-teal-700 {
  --bs-alert-color: var(--bs-teal-700-text);
  --bs-alert-bg: var(--bs-teal-700-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-700-border-subtle);
  --bs-alert-link-color: var(--bs-teal-700-text);
}

.alert-teal-800 {
  --bs-alert-color: var(--bs-teal-800-text);
  --bs-alert-bg: var(--bs-teal-800-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-800-border-subtle);
  --bs-alert-link-color: var(--bs-teal-800-text);
}

.alert-teal-900 {
  --bs-alert-color: var(--bs-teal-900-text);
  --bs-alert-bg: var(--bs-teal-900-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-900-border-subtle);
  --bs-alert-link-color: var(--bs-teal-900-text);
}

.alert-cyan-100 {
  --bs-alert-color: var(--bs-cyan-100-text);
  --bs-alert-bg: var(--bs-cyan-100-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-100-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-100-text);
}

.alert-cyan-200 {
  --bs-alert-color: var(--bs-cyan-200-text);
  --bs-alert-bg: var(--bs-cyan-200-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-200-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-200-text);
}

.alert-cyan-300 {
  --bs-alert-color: var(--bs-cyan-300-text);
  --bs-alert-bg: var(--bs-cyan-300-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-300-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-300-text);
}

.alert-cyan-400 {
  --bs-alert-color: var(--bs-cyan-400-text);
  --bs-alert-bg: var(--bs-cyan-400-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-400-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-400-text);
}

.alert-cyan-500 {
  --bs-alert-color: var(--bs-cyan-500-text);
  --bs-alert-bg: var(--bs-cyan-500-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-500-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-500-text);
}

.alert-cyan-600 {
  --bs-alert-color: var(--bs-cyan-600-text);
  --bs-alert-bg: var(--bs-cyan-600-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-600-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-600-text);
}

.alert-cyan-700 {
  --bs-alert-color: var(--bs-cyan-700-text);
  --bs-alert-bg: var(--bs-cyan-700-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-700-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-700-text);
}

.alert-cyan-800 {
  --bs-alert-color: var(--bs-cyan-800-text);
  --bs-alert-bg: var(--bs-cyan-800-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-800-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-800-text);
}

.alert-cyan-900 {
  --bs-alert-color: var(--bs-cyan-900-text);
  --bs-alert-bg: var(--bs-cyan-900-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-900-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-900-text);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #343a40 !important;
  background-color: RGBA(164, 215, 100, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(25, 25, 25, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #343a40 !important;
  background-color: RGBA(40, 167, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #343a40 !important;
  background-color: RGBA(23, 162, 184, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #343a40 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #343a40 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #fff !important;
  background-color: RGBA(0, 123, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo {
  color: #fff !important;
  background-color: RGBA(102, 16, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #fff !important;
  background-color: RGBA(111, 66, 193, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #fff !important;
  background-color: RGBA(232, 62, 140, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #343a40 !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #343a40 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #343a40 !important;
  background-color: RGBA(40, 167, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal {
  color: #343a40 !important;
  background-color: RGBA(32, 201, 151, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan {
  color: #343a40 !important;
  background-color: RGBA(23, 162, 184, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(52, 58, 64, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #343a40 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-dark {
  color: #fff !important;
  background-color: RGBA(52, 58, 64, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-100 {
  color: #343a40 !important;
  background-color: RGBA(204, 229, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-200 {
  color: #343a40 !important;
  background-color: RGBA(153, 202, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-300 {
  color: #343a40 !important;
  background-color: RGBA(102, 176, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-400 {
  color: #343a40 !important;
  background-color: RGBA(51, 149, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-500 {
  color: #fff !important;
  background-color: RGBA(0, 123, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-600 {
  color: #fff !important;
  background-color: RGBA(0, 98, 204, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-700 {
  color: #fff !important;
  background-color: RGBA(0, 74, 153, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-800 {
  color: #fff !important;
  background-color: RGBA(0, 49, 102, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-900 {
  color: #fff !important;
  background-color: RGBA(0, 25, 51, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-100 {
  color: #343a40 !important;
  background-color: RGBA(224, 207, 252, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-200 {
  color: #343a40 !important;
  background-color: RGBA(194, 159, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-300 {
  color: #343a40 !important;
  background-color: RGBA(163, 112, 247, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-400 {
  color: #fff !important;
  background-color: RGBA(133, 64, 245, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-500 {
  color: #fff !important;
  background-color: RGBA(102, 16, 242, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-600 {
  color: #fff !important;
  background-color: RGBA(82, 13, 194, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-700 {
  color: #fff !important;
  background-color: RGBA(61, 10, 145, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-800 {
  color: #fff !important;
  background-color: RGBA(41, 6, 97, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo-900 {
  color: #fff !important;
  background-color: RGBA(20, 3, 48, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-100 {
  color: #343a40 !important;
  background-color: RGBA(226, 217, 243, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-200 {
  color: #343a40 !important;
  background-color: RGBA(197, 179, 230, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-300 {
  color: #343a40 !important;
  background-color: RGBA(169, 142, 218, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-400 {
  color: #fff !important;
  background-color: RGBA(140, 104, 205, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-500 {
  color: #fff !important;
  background-color: RGBA(111, 66, 193, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-600 {
  color: #fff !important;
  background-color: RGBA(89, 53, 154, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-700 {
  color: #fff !important;
  background-color: RGBA(67, 40, 116, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-800 {
  color: #fff !important;
  background-color: RGBA(44, 26, 77, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple-900 {
  color: #fff !important;
  background-color: RGBA(22, 13, 39, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-100 {
  color: #343a40 !important;
  background-color: RGBA(250, 216, 232, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-200 {
  color: #343a40 !important;
  background-color: RGBA(246, 178, 209, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-300 {
  color: #343a40 !important;
  background-color: RGBA(241, 139, 186, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-400 {
  color: #343a40 !important;
  background-color: RGBA(237, 101, 163, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-500 {
  color: #fff !important;
  background-color: RGBA(232, 62, 140, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-600 {
  color: #fff !important;
  background-color: RGBA(186, 50, 112, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-700 {
  color: #fff !important;
  background-color: RGBA(139, 37, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-800 {
  color: #fff !important;
  background-color: RGBA(93, 25, 56, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink-900 {
  color: #fff !important;
  background-color: RGBA(46, 12, 28, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-100 {
  color: #343a40 !important;
  background-color: RGBA(248, 215, 218, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-200 {
  color: #343a40 !important;
  background-color: RGBA(241, 174, 181, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-300 {
  color: #343a40 !important;
  background-color: RGBA(234, 134, 143, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-400 {
  color: #fff !important;
  background-color: RGBA(227, 93, 106, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-500 {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-600 {
  color: #fff !important;
  background-color: RGBA(176, 42, 55, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-700 {
  color: #fff !important;
  background-color: RGBA(132, 32, 41, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-800 {
  color: #fff !important;
  background-color: RGBA(88, 21, 28, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red-900 {
  color: #fff !important;
  background-color: RGBA(44, 11, 14, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-100 {
  color: #343a40 !important;
  background-color: RGBA(255, 229, 208, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-200 {
  color: #343a40 !important;
  background-color: RGBA(254, 203, 161, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-300 {
  color: #343a40 !important;
  background-color: RGBA(254, 178, 114, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-400 {
  color: #343a40 !important;
  background-color: RGBA(253, 152, 67, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-500 {
  color: #343a40 !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-600 {
  color: #fff !important;
  background-color: RGBA(202, 101, 16, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-700 {
  color: #fff !important;
  background-color: RGBA(152, 76, 12, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-800 {
  color: #fff !important;
  background-color: RGBA(101, 50, 8, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange-900 {
  color: #fff !important;
  background-color: RGBA(51, 25, 4, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-100 {
  color: #343a40 !important;
  background-color: RGBA(255, 243, 205, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-200 {
  color: #343a40 !important;
  background-color: RGBA(255, 230, 156, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-300 {
  color: #343a40 !important;
  background-color: RGBA(255, 218, 106, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-400 {
  color: #343a40 !important;
  background-color: RGBA(255, 205, 57, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-500 {
  color: #343a40 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-600 {
  color: #343a40 !important;
  background-color: RGBA(204, 154, 6, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-700 {
  color: #fff !important;
  background-color: RGBA(153, 116, 4, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-800 {
  color: #fff !important;
  background-color: RGBA(102, 77, 3, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow-900 {
  color: #fff !important;
  background-color: RGBA(51, 39, 1, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-100 {
  color: #343a40 !important;
  background-color: RGBA(212, 237, 218, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-200 {
  color: #343a40 !important;
  background-color: RGBA(169, 220, 181, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-300 {
  color: #343a40 !important;
  background-color: RGBA(126, 202, 143, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-400 {
  color: #343a40 !important;
  background-color: RGBA(83, 185, 106, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-500 {
  color: #343a40 !important;
  background-color: RGBA(40, 167, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-600 {
  color: #fff !important;
  background-color: RGBA(32, 134, 55, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-700 {
  color: #fff !important;
  background-color: RGBA(24, 100, 41, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-800 {
  color: #fff !important;
  background-color: RGBA(16, 67, 28, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green-900 {
  color: #fff !important;
  background-color: RGBA(8, 33, 14, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-100 {
  color: #343a40 !important;
  background-color: RGBA(210, 244, 234, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-200 {
  color: #343a40 !important;
  background-color: RGBA(166, 233, 213, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-300 {
  color: #343a40 !important;
  background-color: RGBA(121, 223, 193, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-400 {
  color: #343a40 !important;
  background-color: RGBA(77, 212, 172, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-500 {
  color: #343a40 !important;
  background-color: RGBA(32, 201, 151, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-600 {
  color: #343a40 !important;
  background-color: RGBA(26, 161, 121, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-700 {
  color: #fff !important;
  background-color: RGBA(19, 121, 91, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-800 {
  color: #fff !important;
  background-color: RGBA(13, 80, 60, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal-900 {
  color: #fff !important;
  background-color: RGBA(6, 40, 30, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-100 {
  color: #343a40 !important;
  background-color: RGBA(209, 236, 241, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-200 {
  color: #343a40 !important;
  background-color: RGBA(162, 218, 227, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-300 {
  color: #343a40 !important;
  background-color: RGBA(116, 199, 212, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-400 {
  color: #343a40 !important;
  background-color: RGBA(69, 181, 198, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-500 {
  color: #343a40 !important;
  background-color: RGBA(23, 162, 184, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-600 {
  color: #fff !important;
  background-color: RGBA(18, 130, 147, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-700 {
  color: #fff !important;
  background-color: RGBA(14, 97, 110, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-800 {
  color: #fff !important;
  background-color: RGBA(9, 65, 74, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan-900 {
  color: #fff !important;
  background-color: RGBA(5, 32, 37, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #a4d764 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #b6df83 !important;
}

.link-secondary {
  color: #191919 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #141414 !important;
}

.link-success {
  color: #28a745 !important;
}
.link-success:hover, .link-success:focus {
  color: #53b96a !important;
}

.link-info {
  color: #17a2b8 !important;
}
.link-info:hover, .link-info:focus {
  color: #45b5c6 !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.link-blue {
  color: #007bff !important;
}
.link-blue:hover, .link-blue:focus {
  color: #0062cc !important;
}

.link-indigo {
  color: #6610f2 !important;
}
.link-indigo:hover, .link-indigo:focus {
  color: #520dc2 !important;
}

.link-purple {
  color: #6f42c1 !important;
}
.link-purple:hover, .link-purple:focus {
  color: #59359a !important;
}

.link-pink {
  color: #e83e8c !important;
}
.link-pink:hover, .link-pink:focus {
  color: #ba3270 !important;
}

.link-red {
  color: #dc3545 !important;
}
.link-red:hover, .link-red:focus {
  color: #b02a37 !important;
}

.link-orange {
  color: #fd7e14 !important;
}
.link-orange:hover, .link-orange:focus {
  color: #fd9843 !important;
}

.link-yellow {
  color: #ffc107 !important;
}
.link-yellow:hover, .link-yellow:focus {
  color: #ffcd39 !important;
}

.link-green {
  color: #28a745 !important;
}
.link-green:hover, .link-green:focus {
  color: #53b96a !important;
}

.link-teal {
  color: #20c997 !important;
}
.link-teal:hover, .link-teal:focus {
  color: #4dd4ac !important;
}

.link-cyan {
  color: #17a2b8 !important;
}
.link-cyan:hover, .link-cyan:focus {
  color: #45b5c6 !important;
}

.link-black {
  color: #343a40 !important;
}
.link-black:hover, .link-black:focus {
  color: #2a2e33 !important;
}

.link-white {
  color: #fff !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-gray {
  color: #6c757d !important;
}
.link-gray:hover, .link-gray:focus {
  color: #565e64 !important;
}

.link-gray-dark {
  color: #343a40 !important;
}
.link-gray-dark:hover, .link-gray-dark:focus {
  color: #2a2e33 !important;
}

.link-blue-100 {
  color: #cce5ff !important;
}
.link-blue-100:hover, .link-blue-100:focus {
  color: #d6eaff !important;
}

.link-blue-200 {
  color: #99caff !important;
}
.link-blue-200:hover, .link-blue-200:focus {
  color: #add5ff !important;
}

.link-blue-300 {
  color: #66b0ff !important;
}
.link-blue-300:hover, .link-blue-300:focus {
  color: #85c0ff !important;
}

.link-blue-400 {
  color: #3395ff !important;
}
.link-blue-400:hover, .link-blue-400:focus {
  color: #5caaff !important;
}

.link-blue-500 {
  color: #007bff !important;
}
.link-blue-500:hover, .link-blue-500:focus {
  color: #0062cc !important;
}

.link-blue-600 {
  color: #0062cc !important;
}
.link-blue-600:hover, .link-blue-600:focus {
  color: #004ea3 !important;
}

.link-blue-700 {
  color: #004a99 !important;
}
.link-blue-700:hover, .link-blue-700:focus {
  color: #003b7a !important;
}

.link-blue-800 {
  color: #003166 !important;
}
.link-blue-800:hover, .link-blue-800:focus {
  color: #002752 !important;
}

.link-blue-900 {
  color: #001933 !important;
}
.link-blue-900:hover, .link-blue-900:focus {
  color: #001429 !important;
}

.link-indigo-100 {
  color: #e0cffc !important;
}
.link-indigo-100:hover, .link-indigo-100:focus {
  color: #e6d9fd !important;
}

.link-indigo-200 {
  color: #c29ffa !important;
}
.link-indigo-200:hover, .link-indigo-200:focus {
  color: #ceb2fb !important;
}

.link-indigo-300 {
  color: #a370f7 !important;
}
.link-indigo-300:hover, .link-indigo-300:focus {
  color: #b58df9 !important;
}

.link-indigo-400 {
  color: #8540f5 !important;
}
.link-indigo-400:hover, .link-indigo-400:focus {
  color: #6a33c4 !important;
}

.link-indigo-500 {
  color: #6610f2 !important;
}
.link-indigo-500:hover, .link-indigo-500:focus {
  color: #520dc2 !important;
}

.link-indigo-600 {
  color: #520dc2 !important;
}
.link-indigo-600:hover, .link-indigo-600:focus {
  color: #420a9b !important;
}

.link-indigo-700 {
  color: #3d0a91 !important;
}
.link-indigo-700:hover, .link-indigo-700:focus {
  color: #310874 !important;
}

.link-indigo-800 {
  color: #290661 !important;
}
.link-indigo-800:hover, .link-indigo-800:focus {
  color: #21054e !important;
}

.link-indigo-900 {
  color: #140330 !important;
}
.link-indigo-900:hover, .link-indigo-900:focus {
  color: #100226 !important;
}

.link-purple-100 {
  color: #e2d9f3 !important;
}
.link-purple-100:hover, .link-purple-100:focus {
  color: #e8e1f5 !important;
}

.link-purple-200 {
  color: #c5b3e6 !important;
}
.link-purple-200:hover, .link-purple-200:focus {
  color: #d1c2eb !important;
}

.link-purple-300 {
  color: #a98eda !important;
}
.link-purple-300:hover, .link-purple-300:focus {
  color: #baa5e1 !important;
}

.link-purple-400 {
  color: #8c68cd !important;
}
.link-purple-400:hover, .link-purple-400:focus {
  color: #7053a4 !important;
}

.link-purple-500 {
  color: #6f42c1 !important;
}
.link-purple-500:hover, .link-purple-500:focus {
  color: #59359a !important;
}

.link-purple-600 {
  color: #59359a !important;
}
.link-purple-600:hover, .link-purple-600:focus {
  color: #472a7b !important;
}

.link-purple-700 {
  color: #432874 !important;
}
.link-purple-700:hover, .link-purple-700:focus {
  color: #36205d !important;
}

.link-purple-800 {
  color: #2c1a4d !important;
}
.link-purple-800:hover, .link-purple-800:focus {
  color: #23153e !important;
}

.link-purple-900 {
  color: #160d27 !important;
}
.link-purple-900:hover, .link-purple-900:focus {
  color: #120a1f !important;
}

.link-pink-100 {
  color: #fad8e8 !important;
}
.link-pink-100:hover, .link-pink-100:focus {
  color: #fbe0ed !important;
}

.link-pink-200 {
  color: #f6b2d1 !important;
}
.link-pink-200:hover, .link-pink-200:focus {
  color: #f8c1da !important;
}

.link-pink-300 {
  color: #f18bba !important;
}
.link-pink-300:hover, .link-pink-300:focus {
  color: #f4a2c8 !important;
}

.link-pink-400 {
  color: #ed65a3 !important;
}
.link-pink-400:hover, .link-pink-400:focus {
  color: #f184b5 !important;
}

.link-pink-500 {
  color: #e83e8c !important;
}
.link-pink-500:hover, .link-pink-500:focus {
  color: #ba3270 !important;
}

.link-pink-600 {
  color: #ba3270 !important;
}
.link-pink-600:hover, .link-pink-600:focus {
  color: #95285a !important;
}

.link-pink-700 {
  color: #8b2554 !important;
}
.link-pink-700:hover, .link-pink-700:focus {
  color: #6f1e43 !important;
}

.link-pink-800 {
  color: #5d1938 !important;
}
.link-pink-800:hover, .link-pink-800:focus {
  color: #4a142d !important;
}

.link-pink-900 {
  color: #2e0c1c !important;
}
.link-pink-900:hover, .link-pink-900:focus {
  color: #250a16 !important;
}

.link-red-100 {
  color: #f8d7da !important;
}
.link-red-100:hover, .link-red-100:focus {
  color: #f9dfe1 !important;
}

.link-red-200 {
  color: #f1aeb5 !important;
}
.link-red-200:hover, .link-red-200:focus {
  color: #f4bec4 !important;
}

.link-red-300 {
  color: #ea868f !important;
}
.link-red-300:hover, .link-red-300:focus {
  color: #ee9ea5 !important;
}

.link-red-400 {
  color: #e35d6a !important;
}
.link-red-400:hover, .link-red-400:focus {
  color: #b64a55 !important;
}

.link-red-500 {
  color: #dc3545 !important;
}
.link-red-500:hover, .link-red-500:focus {
  color: #b02a37 !important;
}

.link-red-600 {
  color: #b02a37 !important;
}
.link-red-600:hover, .link-red-600:focus {
  color: #8d222c !important;
}

.link-red-700 {
  color: #842029 !important;
}
.link-red-700:hover, .link-red-700:focus {
  color: #6a1a21 !important;
}

.link-red-800 {
  color: #58151c !important;
}
.link-red-800:hover, .link-red-800:focus {
  color: #461116 !important;
}

.link-red-900 {
  color: #2c0b0e !important;
}
.link-red-900:hover, .link-red-900:focus {
  color: #23090b !important;
}

.link-orange-100 {
  color: #ffe5d0 !important;
}
.link-orange-100:hover, .link-orange-100:focus {
  color: #ffead9 !important;
}

.link-orange-200 {
  color: #fecba1 !important;
}
.link-orange-200:hover, .link-orange-200:focus {
  color: #fed5b4 !important;
}

.link-orange-300 {
  color: #feb272 !important;
}
.link-orange-300:hover, .link-orange-300:focus {
  color: #fec18e !important;
}

.link-orange-400 {
  color: #fd9843 !important;
}
.link-orange-400:hover, .link-orange-400:focus {
  color: #fdad69 !important;
}

.link-orange-500 {
  color: #fd7e14 !important;
}
.link-orange-500:hover, .link-orange-500:focus {
  color: #fd9843 !important;
}

.link-orange-600 {
  color: #ca6510 !important;
}
.link-orange-600:hover, .link-orange-600:focus {
  color: #a2510d !important;
}

.link-orange-700 {
  color: #984c0c !important;
}
.link-orange-700:hover, .link-orange-700:focus {
  color: #7a3d0a !important;
}

.link-orange-800 {
  color: #653208 !important;
}
.link-orange-800:hover, .link-orange-800:focus {
  color: #512806 !important;
}

.link-orange-900 {
  color: #331904 !important;
}
.link-orange-900:hover, .link-orange-900:focus {
  color: #291403 !important;
}

.link-yellow-100 {
  color: #fff3cd !important;
}
.link-yellow-100:hover, .link-yellow-100:focus {
  color: #fff5d7 !important;
}

.link-yellow-200 {
  color: #ffe69c !important;
}
.link-yellow-200:hover, .link-yellow-200:focus {
  color: #ffebb0 !important;
}

.link-yellow-300 {
  color: #ffda6a !important;
}
.link-yellow-300:hover, .link-yellow-300:focus {
  color: #ffe188 !important;
}

.link-yellow-400 {
  color: #ffcd39 !important;
}
.link-yellow-400:hover, .link-yellow-400:focus {
  color: #ffd761 !important;
}

.link-yellow-500 {
  color: #ffc107 !important;
}
.link-yellow-500:hover, .link-yellow-500:focus {
  color: #ffcd39 !important;
}

.link-yellow-600 {
  color: #cc9a06 !important;
}
.link-yellow-600:hover, .link-yellow-600:focus {
  color: #d6ae38 !important;
}

.link-yellow-700 {
  color: #997404 !important;
}
.link-yellow-700:hover, .link-yellow-700:focus {
  color: #7a5d03 !important;
}

.link-yellow-800 {
  color: #664d03 !important;
}
.link-yellow-800:hover, .link-yellow-800:focus {
  color: #523e02 !important;
}

.link-yellow-900 {
  color: #332701 !important;
}
.link-yellow-900:hover, .link-yellow-900:focus {
  color: #291f01 !important;
}

.link-green-100 {
  color: #d4edda !important;
}
.link-green-100:hover, .link-green-100:focus {
  color: #ddf1e1 !important;
}

.link-green-200 {
  color: #a9dcb5 !important;
}
.link-green-200:hover, .link-green-200:focus {
  color: #bae3c4 !important;
}

.link-green-300 {
  color: #7eca8f !important;
}
.link-green-300:hover, .link-green-300:focus {
  color: #98d5a5 !important;
}

.link-green-400 {
  color: #53b96a !important;
}
.link-green-400:hover, .link-green-400:focus {
  color: #75c788 !important;
}

.link-green-500 {
  color: #28a745 !important;
}
.link-green-500:hover, .link-green-500:focus {
  color: #53b96a !important;
}

.link-green-600 {
  color: #208637 !important;
}
.link-green-600:hover, .link-green-600:focus {
  color: #1a6b2c !important;
}

.link-green-700 {
  color: #186429 !important;
}
.link-green-700:hover, .link-green-700:focus {
  color: #135021 !important;
}

.link-green-800 {
  color: #10431c !important;
}
.link-green-800:hover, .link-green-800:focus {
  color: #0d3616 !important;
}

.link-green-900 {
  color: #08210e !important;
}
.link-green-900:hover, .link-green-900:focus {
  color: #061a0b !important;
}

.link-teal-100 {
  color: #d2f4ea !important;
}
.link-teal-100:hover, .link-teal-100:focus {
  color: #dbf6ee !important;
}

.link-teal-200 {
  color: #a6e9d5 !important;
}
.link-teal-200:hover, .link-teal-200:focus {
  color: #b8eddd !important;
}

.link-teal-300 {
  color: #79dfc1 !important;
}
.link-teal-300:hover, .link-teal-300:focus {
  color: #94e5cd !important;
}

.link-teal-400 {
  color: #4dd4ac !important;
}
.link-teal-400:hover, .link-teal-400:focus {
  color: #71ddbd !important;
}

.link-teal-500 {
  color: #20c997 !important;
}
.link-teal-500:hover, .link-teal-500:focus {
  color: #4dd4ac !important;
}

.link-teal-600 {
  color: #1aa179 !important;
}
.link-teal-600:hover, .link-teal-600:focus {
  color: #48b494 !important;
}

.link-teal-700 {
  color: #13795b !important;
}
.link-teal-700:hover, .link-teal-700:focus {
  color: #0f6149 !important;
}

.link-teal-800 {
  color: #0d503c !important;
}
.link-teal-800:hover, .link-teal-800:focus {
  color: #0a4030 !important;
}

.link-teal-900 {
  color: #06281e !important;
}
.link-teal-900:hover, .link-teal-900:focus {
  color: #052018 !important;
}

.link-cyan-100 {
  color: #d1ecf1 !important;
}
.link-cyan-100:hover, .link-cyan-100:focus {
  color: #daf0f4 !important;
}

.link-cyan-200 {
  color: #a2dae3 !important;
}
.link-cyan-200:hover, .link-cyan-200:focus {
  color: #b5e1e9 !important;
}

.link-cyan-300 {
  color: #74c7d4 !important;
}
.link-cyan-300:hover, .link-cyan-300:focus {
  color: #90d2dd !important;
}

.link-cyan-400 {
  color: #45b5c6 !important;
}
.link-cyan-400:hover, .link-cyan-400:focus {
  color: #6ac4d1 !important;
}

.link-cyan-500 {
  color: #17a2b8 !important;
}
.link-cyan-500:hover, .link-cyan-500:focus {
  color: #45b5c6 !important;
}

.link-cyan-600 {
  color: #128293 !important;
}
.link-cyan-600:hover, .link-cyan-600:focus {
  color: #0e6876 !important;
}

.link-cyan-700 {
  color: #0e616e !important;
}
.link-cyan-700:hover, .link-cyan-700:focus {
  color: #0b4e58 !important;
}

.link-cyan-800 {
  color: #09414a !important;
}
.link-cyan-800:hover, .link-cyan-800:focus {
  color: #07343b !important;
}

.link-cyan-900 {
  color: #052025 !important;
}
.link-cyan-900:hover, .link-cyan-900:focus {
  color: #041a1e !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-100-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-200-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-300-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-400-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-500-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-600-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-700-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-800-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-900-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-100-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-200-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-300-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-400-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-500-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-600-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-700-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-800-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-900-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-100-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-200-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-300-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-400-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-500-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-600-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-700-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-800-rgb), var(--bs-border-opacity)) !important;
}

.border-purple-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-900-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-100-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-200-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-300-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-400-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-500-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-600-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-700-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-800-rgb), var(--bs-border-opacity)) !important;
}

.border-pink-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-900-rgb), var(--bs-border-opacity)) !important;
}

.border-red-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-100-rgb), var(--bs-border-opacity)) !important;
}

.border-red-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-200-rgb), var(--bs-border-opacity)) !important;
}

.border-red-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-300-rgb), var(--bs-border-opacity)) !important;
}

.border-red-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-400-rgb), var(--bs-border-opacity)) !important;
}

.border-red-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-500-rgb), var(--bs-border-opacity)) !important;
}

.border-red-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-600-rgb), var(--bs-border-opacity)) !important;
}

.border-red-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-700-rgb), var(--bs-border-opacity)) !important;
}

.border-red-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-800-rgb), var(--bs-border-opacity)) !important;
}

.border-red-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-900-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-100-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-200-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-300-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-400-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-500-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-600-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-700-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-800-rgb), var(--bs-border-opacity)) !important;
}

.border-orange-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-900-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-100-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-200-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-300-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-400-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-500-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-600-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-700-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-800-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-900-rgb), var(--bs-border-opacity)) !important;
}

.border-green-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-100-rgb), var(--bs-border-opacity)) !important;
}

.border-green-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-200-rgb), var(--bs-border-opacity)) !important;
}

.border-green-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-300-rgb), var(--bs-border-opacity)) !important;
}

.border-green-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-400-rgb), var(--bs-border-opacity)) !important;
}

.border-green-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-500-rgb), var(--bs-border-opacity)) !important;
}

.border-green-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-600-rgb), var(--bs-border-opacity)) !important;
}

.border-green-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-700-rgb), var(--bs-border-opacity)) !important;
}

.border-green-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-800-rgb), var(--bs-border-opacity)) !important;
}

.border-green-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-900-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-100-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-200-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-300-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-400-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-500-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-600-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-700-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-800-rgb), var(--bs-border-opacity)) !important;
}

.border-teal-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-900-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-100-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-200-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-300-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-400-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-500-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-600-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-700-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-800-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-900-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-100-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-200-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-300-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-400-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-500-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-600-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-700-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-800-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-900-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-100-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-200-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-300-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-400-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-500-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-600-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-700-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-800-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-900-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-100-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-200-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-300-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-400-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-500-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-600-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-700-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-800-rgb), var(--bs-text-opacity)) !important;
}

.text-purple-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-900-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-100-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-200-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-300-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-400-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-500-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-600-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-700-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-800-rgb), var(--bs-text-opacity)) !important;
}

.text-pink-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-900-rgb), var(--bs-text-opacity)) !important;
}

.text-red-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-100-rgb), var(--bs-text-opacity)) !important;
}

.text-red-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-200-rgb), var(--bs-text-opacity)) !important;
}

.text-red-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-300-rgb), var(--bs-text-opacity)) !important;
}

.text-red-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-400-rgb), var(--bs-text-opacity)) !important;
}

.text-red-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-500-rgb), var(--bs-text-opacity)) !important;
}

.text-red-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-600-rgb), var(--bs-text-opacity)) !important;
}

.text-red-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-700-rgb), var(--bs-text-opacity)) !important;
}

.text-red-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-800-rgb), var(--bs-text-opacity)) !important;
}

.text-red-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-900-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-100-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-200-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-300-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-400-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-500-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-600-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-700-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-800-rgb), var(--bs-text-opacity)) !important;
}

.text-orange-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-900-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-100-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-200-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-300-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-400-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-500-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-600-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-700-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-800-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-900-rgb), var(--bs-text-opacity)) !important;
}

.text-green-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-100-rgb), var(--bs-text-opacity)) !important;
}

.text-green-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-200-rgb), var(--bs-text-opacity)) !important;
}

.text-green-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-300-rgb), var(--bs-text-opacity)) !important;
}

.text-green-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-400-rgb), var(--bs-text-opacity)) !important;
}

.text-green-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-500-rgb), var(--bs-text-opacity)) !important;
}

.text-green-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-600-rgb), var(--bs-text-opacity)) !important;
}

.text-green-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-700-rgb), var(--bs-text-opacity)) !important;
}

.text-green-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-800-rgb), var(--bs-text-opacity)) !important;
}

.text-green-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-900-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-100-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-200-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-300-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-400-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-500-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-600-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-700-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-800-rgb), var(--bs-text-opacity)) !important;
}

.text-teal-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-900-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-100-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-200-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-300-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-400-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-500-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-600-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-700-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-800-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-900-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(52, 58, 64, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-emphasis {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-emphasis-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Ysabeau Office", sans-serif;
  color: #444444;
}

a {
  color: #191919;
  text-decoration: none;
}

a:hover {
  color: #a4d764;
  text-decoration: none;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Ysabeau Office", sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.sliderPrincipal .swiper-pagination-bullet {
  background: white;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  opacity: 0.4;
}
.sliderPrincipal .swiper-pagination-bullet-active {
  background-color: white;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  opacity: 1;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #a4d764;
  width: 40px;
  height: 40px;
  transition: all 0.4s;
  border: 2px solid #fff;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: white;
  color: #a4d764 !important;
  border: 2px solid #a4d764;
}
.back-to-top:hover i {
  font-size: 28px;
  color: #a4d764 !important;
  line-height: 0;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #a4d764;
  border-top-color: #efefef;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #fff;
  font-size: 15px;
  height: 40px;
  padding: 0;
  color: #6c757d;
}
#topbar .contact-info a {
  line-height: 0;
  color: #6c757d;
  transition: 0.3s;
}
#topbar .contact-info a:hover {
  color: #a4d764;
}
#topbar .contact-info i {
  color: #a4d764;
  line-height: 0;
  margin-right: 5px;
  margin-left: 10px;
}
#topbar .contact-info .phone-icon {
  margin-left: 15px;
}
#topbar .social-links a {
  color: #6c757d;
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
  font-size: 1.5em;
}
#topbar .social-links a:hover {
  color: #a4d764;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  height: 70px;
}
#header.fixed-top {
  background: #fff;
}
#header .idiomaEs {
  border-radius: 20px 0px 0px 20px;
  margin-right: 0;
  border: 0px;
  margin-left: 25px;
}
#header .idiomaEn {
  border-radius: 0px 20px 20px 0px;
  margin-left: 0px;
  border-left: solid 1px #fff !important;
  border: 0px;
}
#header .appointment-btn {
  background: #a4d764;
  color: #fff;
  padding: 8px 25px;
  white-space: nowrap;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}
@media (max-width: 430px) {
  #header .appointment-btn {
    padding: 4px 13px;
    font-size: 12px;
  }
  #header .idiomaEs {
    margin-left: 8px;
  }
}
#header .appointment-btn:hover {
  background: #fff;
  color: #a4d764;
  border: solid 1px #a4d764 !important;
}
#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#header .logo a {
  color: #a4d764;
}
#header .logo img {
  max-height: 60px;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
* {
  -webkit-font-smoothing: antialiased;
}

.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar > ul > li {
  margin-left: 5px;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px 24px 15px;
  font-size: 17px;
  color: #6c757d;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
@media (max-width: 1400px) {
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 9px 16px 9px;
    font-size: 14px;
    color: #6c757d;
    white-space: nowrap;
    transition: 0.3s;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  background: #a4d764;
  color: #fff;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  color: #191919;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #fff;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #a4d764;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 1200px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(140, 205, 59, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #191919;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #a4d764;
  background: none;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #a4d764;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: calc(100vh - 110px);
  background: url("../public/images/Portada.jpg") top center;
  background-size: cover;
  position: relative;
  background-position: right;
}
#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero h1, #hero .h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 2px black;
}
#hero h2, #hero .h2 {
  color: #fff;
  text-shadow: 1px 1px 2px black;
  margin-bottom: 30px;
  font-size: 24px;
}
#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  border: 2px solid #fff;
  color: #fff;
}
#hero .btn-get-started:hover {
  background: #fff;
  border-color: #a4d764;
  color: #a4d764;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 1200px) {
  #hero {
    text-align: center;
  }
}
@media (max-width: 768px) {
  #hero {
    text-align: center;
  }
  #hero .container {
    padding-top: 40px;
  }
  #hero h1, #hero .h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2, #hero .h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

#home {
  padding-top: 0;
  background-position: 100%;
  background-size: cover;
  height: calc(100vh - 70px);
  position: relative;
  width: 100%;
}
#home img {
  object-fit: fill;
  width: 100%;
}
#home .swiper {
  height: 100%;
}
#home .swiper-slide {
  background-size: cover;
  background-position: right;
  text-align: left;
}
#home .swiper-slide img {
  object-fit: cover;
  width: 100%;
}
#home .swiper-cube {
  overflow: visible;
  height: 0;
}
#home .msnprincipal {
  background: hsla(0deg, 0%, 95%, 0.651);
  padding: 20px;
  margin-left: 15%;
  text-align: center;
  border-radius: 15px;
}
#home .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  border: 2px solid #23610B;
  color: #23610B;
}
#home .btn-get-started:hover {
  background: #fff;
  border-color: #23610B;
  color: #23610B;
}
#home h1, #home .h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #23610B;
}
#home h2, #home .h2 {
  color: #23610B;
  margin-bottom: 30px;
  font-size: 24px;
}
#home span {
  color: white;
  font-weight: bold;
}
#home .btn {
  background: rgba(0, 0, 0, 0.6);
  border: none;
}
#home .btn:hover {
  background: rgba(255, 255, 255, 0.6);
}
#home .slider-caption {
  position: relative;
  color: white;
  padding: 4px;
  font-size: 17px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 18px;
}
@media (min-width: 1024px) {
  #home {
    background-attachment: fixed;
  }
}
@media (max-width: 1200px) {
  #home {
    text-align: center;
  }
  #home .swiper-slide {
    text-align: center;
  }
  #home .msnprincipal {
    margin: auto;
  }
}
@media (max-width: 768px) {
  #home {
    text-align: center;
  }
  #home .swiper-slide {
    text-align: center;
  }
  #home .container {
    padding-top: 40px;
  }
  #home h1, #home .h1 {
    font-size: 32px;
    line-height: 36px;
  }
  #home h2, #home .h2 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 40px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title h2, .section-title .h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}
@media (max-width: 575px) {
  .section-title h2, .section-title .h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .section-title span {
    font-size: 38px;
  }
}

.section-map {
  padding: 30px 0;
  position: relative;
}
.section-map #map {
  height: 600px;
}

.img-alto {
  max-height: 100%;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .img-alto {
  max-height: 100%;
}
.about .content h3, .about .content .h3 {
  font-weight: 600;
  font-size: 26px;
  color: #a4d764;
}
.about .content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.about .content ul li {
  padding-bottom: 10px;
}
.about .content ul i {
  font-size: 20px;
  padding-right: 8px;
  color: #a4d764;
}
.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# centros
--------------------------------------------------------------*/
.centros {
  padding-top: 10px;
}
.centros .box {
  padding: 30px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
  height: 100%;
  text-align: center;
}
.centros .box span {
  display: block;
  font-size: 22px;
  font-weight: 700;
  color: #a4d764;
}
.centros .box h4, .centros .box .h4 {
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #191919;
}
.centros .box p {
  color: #191919;
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.centros .box:hover {
  background: #a4d764;
  padding: 20px 20px 40px 20px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}
.centros .box:hover span, .centros .box:hover h4, .centros .box:hover .h4 {
  color: #fff;
}

/*--------------------------------------------------------------
# exp
--------------------------------------------------------------*/
.exp .content h3, .exp .content .h3 {
  font-weight: 600;
  font-size: 26px;
  color: #a4d764;
}
.exp .content ul {
  list-style: none;
  padding: 0;
}
.exp .content ul li {
  padding-bottom: 10px;
}
.exp .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #a4d764;
}
.exp .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding-top: 10px;
}
.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
}
.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #d4ecb5;
}
.why-us .box h4, .why-us .box .h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #191919;
}
.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}
.why-us .box:hover {
  background: #a4d764;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}
.why-us .box:hover span, .why-us .box:hover h4, .why-us .box:hover .h4, .why-us .box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  background: #f2f2f2;
  padding: 15px 0;
  text-align: center;
}
.clients img {
  width: 50%;
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}
.clients img:hover {
  filter: none;
  transform: scale(1.2);
}
@media (max-width: 768px) {
  .clients img {
    width: 40%;
  }
}

/*--------------------------------------------------------------
#tratamientos
--------------------------------------------------------------*/
.tratamientos .icon-box {
  text-align: center;
  padding: 10px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.tratamientos .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #a4d764;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.tratamientos .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}
.tratamientos .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: white;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.tratamientos .icon-box h3, .tratamientos .icon-box .h3 {
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 24px;
  color: #a4d764;
}
.tratamientos .icon-box h3 a, .tratamientos .icon-box .h3 a {
  color: #a4d764;
}
.tratamientos .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.tratamientos .icon-box:hover {
  background: #a4d764;
  border-color: #a4d764;
  padding: 18px 30px 30px 30px;
}
.tratamientos .icon-box:hover .icon {
  background: #fff;
}
.tratamientos .icon-box:hover .icon i {
  color: #a4d764;
}
.tratamientos .icon-box:hover .icon::before {
  background: #bce18d;
}
.tratamientos .icon-box:hover h3 a, .tratamientos .icon-box:hover .h3 a {
  color: #fff;
}
.tratamientos .icon-box:hover a u {
  color: #fff;
}
.tratamientos .icon-box:hover p {
  color: #191919;
}

/*--------------------------------------------------------------
#tratamientos
--------------------------------------------------------------*/
.secundario {
  text-align: center;
  margin-bottom: 70px;
}
.secundario h3, .secundario .h3 {
  color: #a4d764;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: bold;
}
.secundario p b {
  font-size: 24px;
}

@media (min-width: 991px) {
  .centrado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.container p b {
  font-size: 24px;
}

.icon-box {
  text-align: center;
  padding: 10px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #a4d764;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}
.icon-box .icon i {
  color: #fff;
  font-size: 28px;
}
.icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: white;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}
.icon-box h4, .icon-box .h4 {
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 24px;
  color: #a4d764;
}
.icon-box h4 a, .icon-box .h4 a {
  color: #a4d764;
}
.icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.icon-box:hover {
  background: #a4d764;
  border-color: #a4d764;
  padding: 18px 18px 18px 18px;
}
.icon-box:hover .icon {
  background: #fff;
}
.icon-box:hover .icon i {
  color: #a4d764;
}
.icon-box:hover .icon::before {
  background: #bce18d;
}
.icon-box:hover h4 a, .icon-box:hover .h4 a {
  color: #fff;
}
.icon-box:hover p {
  color: #191919;
}

.testimonials {
  background: url("../public/images/fondoopinion2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  position: relative;
  right: -5px;
  top: 10px;
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.testimonials .testimonial-item .h3, .testimonials .testimonial-item h3 {
  color: #b66c2a;
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 5px;
}

.testimonials .testimonial-item .h4, .testimonials .testimonial-item h4 {
  color: #b66c2a;
  font-size: 14px;
  margin: 0 0 10px;
}

.testimonials .testimonial-item p {
  font-size: 18px;
  font-weight: 700;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .swiper-slide {
  background: rgba(5, 170, 51, 0);
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: url("../public/images/eligetucentro.png");
  background-size: cover;
  padding: 200px 0px 40px 0;
  background-position: center;
}
.cta h3, .cta .h3 {
  color: #a4d764;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 1px 1px 2px black;
}
.cta p {
  color: #a4d764;
  text-shadow: 1px 1px 2px black;
  font-size: 24px;
}
.cta .cta-btn {
  background-color: rgba(255, 255, 255, 0.95);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  font-weight: bold;
  margin-top: 10px;
  border: 2px solid #a4d764;
  color: #a4d764;
}
.cta .cta-btn:hover {
  background: #a4d764;
  border: 2px solid #a4d764;
  color: #fff;
}

.job {
  background: url("../public/images/trabaja.png");
  background-size: cover;
  padding: 120px 0px 120px 0;
  background-position: center;
}
.job h3, .job .h3 {
  color: #a4d764;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 1px 1px 2px black;
}
.job p {
  color: #a4d764;
  text-shadow: 1px 1px 2px black;
  font-size: 24px;
}
.job .cta-btn {
  background-color: rgba(255, 255, 255, 0.95);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  transition: 0.5s;
  font-weight: bold;
  margin-top: 10px;
  border: 2px solid #a4d764;
  color: #a4d764;
}
.job .cta-btn:hover {
  background: #a4d764;
  border: 2px solid #a4d764;
  color: #fff;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  font-family: "Poppins", sans-serif;
}
.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #bce18d;
}
.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfolio .portfolio-item {
  margin-bottom: 30px;
}
.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}
.portfolio .portfolio-item .portfolio-info h4, .portfolio .portfolio-item .portfolio-info .h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #191919;
}
.portfolio .portfolio-item .portfolio-info p {
  color: #4c4c4c;
  font-size: 14px;
  margin-bottom: 0;
}
.portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #333333;
}
.portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #a4d764;
}
.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}
.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}
.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}
.portfolio .portfolio-item .portfolio-links a:hover {
  color: #bce18d;
}
.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #a4d764;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #a4d764;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(25, 25, 25, 0.08);
}
.portfolio-details .portfolio-info h3, .portfolio-details .portfolio-info .h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2, .portfolio-details .portfolio-description .h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}

.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 65px;
  z-index: 99999;
  background: #23610B;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  transition: all 0.4s;
}
.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}
.scroll-top:hover {
  background: #348f10;
  color: #fff;
}
.scroll-top.active {
  visibility: visible;
  opacity: 1;
}
.scroll-top#WhatsappFlotante {
  bottom: 70px;
  visibility: visible;
  opacity: 1;
}
.scroll-top#WhatsappFlotante .whatsapp {
  margin-top: 5px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.pricing .box h3, .pricing .box .h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #191919;
}
.pricing .box h4, .pricing .box .h4 {
  font-size: 42px;
  color: #a4d764;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}
.pricing .box h4 sup, .pricing .box .h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}
.pricing .box h4 span, .pricing .box .h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}
.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #191919;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.pricing .box ul li {
  padding-bottom: 16px;
}
.pricing .box ul i {
  color: #a4d764;
  font-size: 18px;
  padding-right: 4px;
}
.pricing .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}
.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}
.pricing .box .btn-buy {
  background: #a4d764;
  display: inline-block;
  padding: 10px 40px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}
.pricing .box .btn-buy:hover {
  background: #bce18d;
}
.pricing .featured {
  background: #a4d764;
  box-shadow: none;
  color: #fff;
}
.pricing .featured h3, .pricing .featured .h3, .pricing .featured h4, .pricing .featured .h4, .pricing .featured ul, .pricing .featured ul li, .pricing .featured ul .na {
  color: #fff;
}
.pricing .featured h4 span, .pricing .featured .h4 span {
  color: rgba(255, 255, 255, 0.58);
}
.pricing .featured .btn-wrap {
  padding: 15px;
  text-align: center;
}
.pricing .featured .btn-buy {
  background: rgba(255, 255, 255, 0.2);
}
.pricing .featured .btn-buy:hover {
  background: #fff;
  color: #a4d764;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  background: #fff;
}
.team .member img {
  max-width: 60%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}
.team .member h4, .team .member .h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}
.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}
.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}
.team .member .social {
  margin-top: 15px;
}
.team .member .social a {
  color: #919191;
  transition: 0.3s;
}
.team .member .social a:hover {
  color: #a4d764;
}
.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .button {
  background: #a4d764;
  border: 0;
  padding: 10px 44px;
  color: #fff;
  transition: 0.4s;
  margin-top: 20px;
}
.contact .button:hover {
  background: #bce18d;
}
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 50px 0 50px 0;
}
.contact .info-box i {
  font-size: 50px;
  color: #a4d764;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #a4d764;
}
.contact .info-box h3, .contact .info-box .h3 {
  font-size: 25px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 20px;
  margin-bottom: 0;
}
.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  background-color: #a4d764;
}
.contact .php-email-form input {
  padding: 10px 15px;
}
.contact .php-email-form textarea {
  padding: 12px 15px;
}
.contact .php-email-form button [type=submit] {
  background: #a4d764;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}
.contact .php-email-form button [type=submit]:hover {
  background: #bce18d;
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #a4d764;
}
.breadcrumbs h2, .breadcrumbs .h2 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.breadcrumbs .tituloApartado {
  color: #fff;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 0px 0;
  margin: 0;
  font-size: 18px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
  color: #fff;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
.breadcrumbs ol li a {
  color: #fff;
}

/*--------------------------------------------------------------
# Apartados
--------------------------------------------------------------*/
.inner-page .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #a4d764;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #a4d764;
  padding: 0 0 30px 0;
  color: #191919;
  font-size: 14px;
}
#footer .footer-kit {
  padding: 60px 0 30px 0;
  background: #fff;
}
#footer .footer-top {
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: #a4d764;
  color: #191919;
  /*border-top: 4px solid $secondary;*/
  text-align: center;
}
#footer .footer-top .footer-info h3, #footer .footer-top .footer-info .h3 {
  font-size: 36px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info .img-footer {
  max-width: 40%;
  height: auto;
}
#footer .footer-top .footer-info p {
  font-size: 17px;
  margin-top: 10px;
  line-height: 24px;
  margin-bottom: 0;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #262626;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #a4d764;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4, #footer .footer-top .h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #191919;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 17px;
}
#footer .footer-top .footer-links ul a:hover {
  color: #fff;
}
#footer .footer-top .footer-links .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #a4d764;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .footer-links .social-links a:hover {
  background: #bce18d;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #a4d764;
  color: #fff;
  transition: 0.3s;
  border-radius: 4;
}
#footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #bce18d;
}
#footer .copyright {
  border-top: 1px solid #262626;
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
#footer .credits a {
  color: #bce18d;
}
@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiperIMGgrande {
  height: 600px;
  width: 100%;
}

.mySwiperGaleria {
  height: 200px;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiperGaleria .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiperGaleria .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}